import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {  useNavigate, useParams } from 'react-router-dom';
import { PlayerData, Team } from '../../../utilities/types';
import { apiClient } from '../../../utilities/apiClient';
import { Breadcrumbs } from '@mui/material';
import { AdminLayout } from '../../../layouts/AdminLayout';

const TeamPlayersUpload = () => {
    const [players, setPlayers] = useState<PlayerData[]>([]);
    const [selectedPlayers, setSelectedPlayers] = useState<number[]>([]);
    const { teamId } = useParams<{ teamId: string }>();
    const [team, setTeam] = useState<Team | null>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        apiClient.get('/players')
            .then(response => setPlayers(response.data))
            .catch(error => console.error('Error fetching players:', error));

        apiClient.get(`/teams/${teamId}`)
            .then(response => setTeam(response.data))
            .catch(error => console.error('Error fetching team data:', error));
    }, [teamId]);

    const handlePostTeamPlayers = () => {
        if (!team) {
            console.error('Team data is not available');
            return;
        }

        const payload = {
            team: {
                id: team.id,
                name: team.name,
                clubId: team.clubId,
                competitionId: team.competitionId
            },
            players: selectedPlayers,
            teamPlayerStatus: 'ACTIVE'
        };

        apiClient.post('/team-players/save', payload)
            .then(response => navigate(`/admin/team/${teamId}/players`))
            .catch(error => console.error('Error posting team players:', error));
    };

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'name', headerName: 'Name', width: 150 },
        { field: 'middleName', headerName: 'Middle Name', width: 150 },
        { field: 'lastName', headerName: 'Last Name', width: 150 },
        { field: 'gender', headerName: 'Gender', width: 120 },
        { field: 'province', headerName: 'Province', width: 120 },
    ];

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    // Filter players based on search query
    const filteredPlayers = players.filter(player =>
        player.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        player.middleName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        player.lastName?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <AdminLayout>
            <Breadcrumbs />
            <div style={{ height: 600, width: '100%', padding: '20px', backgroundColor: '#f5f5f5' }}>
                <h1>Add Players to {team?.name} Team Roster</h1>
                <input
                    type="text"
                    placeholder="Search by Name, Middle Name, or Last Name..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    style={{ marginBottom: '10px', padding: '8px', width: '100%' }}
                />
                <DataGrid
                    rows={filteredPlayers}
                    columns={columns}
                    checkboxSelection
                    filterMode='client'
                    onRowSelectionModelChange={(selection) => setSelectedPlayers(selection as number[])}
                />
                <p style={{ marginTop: '20px' }}>Selected Players: {selectedPlayers.join(', ')}</p>
                <button 
                    onClick={handlePostTeamPlayers} 
                    style={{ padding: '10px 20px', backgroundColor: 'green', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
                >
                    Post Selected Players
                </button>
            </div>
        </AdminLayout>
    );
};

export default TeamPlayersUpload;