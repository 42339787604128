import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  useReactTable,
  ColumnDef,
  getCoreRowModel,
} from "@tanstack/react-table";
import { apiClient } from "../../../utilities/apiClient";
import { AdminLayout } from "../../../layouts/AdminLayout";
import BreadCrumbs from "../../../components/Breadcrumbs";

const initialVenue = {
  name: "",
  location: "",
  capacity: "",
  description: "",
  image: "",
  clubId: "",
};

const VenueList: React.FC = () => {
  const [venues, setVenues] = useState<Venue[]>([]);
  interface Club {
    id: number;
    name: string;
  }

  const [clubs, setClubs] = useState<Club[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  interface Venue {
    id?: number;
    name: string;
    location: string;
    capacity: string;
    description: string;
    image: string;
    club?: { id: number; name: string };
  }
  
  const [currentVenue, setCurrentVenue] = useState<Venue | null>(null);
  const [formState, setFormState] = useState(initialVenue);
  const [saving, setSaving] = useState(false); // New loading state for save operation


  useEffect(() => {
    setLoading(true);
 Promise.all([]).then(() => {
      apiClient.get<Venue[]>("/venues")
        .then((response) => setVenues(response.data))
        .catch((error) => console.error("Error fetching venues:", error));
      apiClient.get<Club[]>("/clubs")
        .then((response) => setClubs(response.data))
        .catch((error) => console.error("Error fetching clubs:", error));
    }).finally(() => setLoading(false));
  }, []);

  const openDialog = (venue: Venue | null = null) => {
    if (venue) {
      setFormState({
        name: venue.name,
        location: venue.location,
        capacity: venue.capacity.toString(),
        description: venue.description,
        image: venue.image,
        clubId: venue.club?.id?.toString() || "",
      });
    } else {
      setFormState(initialVenue);
    }
    setCurrentVenue(venue);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setCurrentVenue(null);
  };

  const handleSave = () => {
    setSaving(true); // Start loading
  
    const selectedClub = clubs.find((c) => c.id === parseInt(formState.clubId));
  
    const venueData = {
      id: currentVenue?.id || 0, // Ensure id is present
      name: formState.name,
      location: formState.location,
      capacity: parseInt(formState.capacity, 10), // Convert to number
      description: formState.description,
      image: formState.image,
      club: selectedClub || null, // Send full club object
    };
  
    const apiCall = currentVenue
      ? apiClient.put(`/venues/${currentVenue.id}`, venueData)
      : apiClient.post("/venues", venueData);
  
    apiCall
      .then((response) => {
        setVenues((prev) =>
          currentVenue
            ? prev.map((v) => (v.id === currentVenue.id ? response.data : v))
            : [...prev, response.data]
        );
        closeDialog();
      })
      .catch((error) => console.error("Error saving venue:", error))
      .finally(() => setSaving(false)); // Stop loading
  };
 

  const columns: ColumnDef<Venue>[] = [
    { accessorKey: "id", header: "ID" },
    { accessorKey: "name", header: "Name" },
    { accessorKey: "location", header: "Location" },
    { accessorKey: "capacity", header: "Capacity" },
    {
      accessorKey: "club.name",
      header: "Club",
      cell: ({ row }) => <>{row.original.club?.name || "N/A"}</>,
    },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => (
        <Button
          variant="contained"
          sx={{
            color: "white",
            backgroundColor: "green",
            "&:hover": { backgroundColor: "darkgreen" },
          }}
          onClick={() => openDialog(row.original)}
        >
          Edit
        </Button>
      ),
    },
  ];

  const table = useReactTable({
    data: venues,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
  
      </Box>
    );
  }
  return (
    <AdminLayout>
      <BreadCrumbs />
      <Container maxWidth="md">
        <Box>
          <Typography variant="h4" gutterBottom>
            Venue Management
          </Typography>
          <Paper sx={{ padding: 2 }}>
            <Button variant="contained" color="success" onClick={() => openDialog()}>
              Add New Venue
            </Button>
            <Table>
              <TableHead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <TableCell key={header.id}>
                        {typeof header.column.columnDef.header === "function"
                          ? header.column.columnDef.header(header.getContext())
                          : header.column.columnDef.header}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {typeof cell.column.columnDef.cell === "function"
                          ? cell.column.columnDef.cell(cell.getContext())
                          : cell.getValue()}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>

          {/* Venue Dialog Form */}
          <Dialog open={dialogOpen} onClose={closeDialog}>
            <DialogTitle>{currentVenue ? "Edit Venue" : "Add New Venue"}</DialogTitle>
            <DialogContent>
              <TextField
                label="Venue Name"
                fullWidth
                margin="dense"
                value={formState.name}
                onChange={(e) => setFormState({ ...formState, name: e.target.value })}
              />
              <TextField
                label="Location"
                fullWidth
                margin="dense"
                value={formState.location}
                onChange={(e) => setFormState({ ...formState, location: e.target.value })}
              />
              <TextField
                label="Capacity"
                type="number"
                fullWidth
                margin="dense"
                value={formState.capacity}
                onChange={(e) => setFormState({ ...formState, capacity: e.target.value })}
              />
              <TextField
                label="Description"
                fullWidth
                margin="dense"
                multiline
                rows={3}
                value={formState.description}
                onChange={(e) => setFormState({ ...formState, description: e.target.value })}
              />
              <TextField
                label="Image URL"
                fullWidth
                margin="dense"
                value={formState.image}
                onChange={(e) => setFormState({ ...formState, image: e.target.value })}
              />
              <TextField
                select
                label="Club"
                fullWidth
                margin="dense"
                value={formState.clubId}
                onChange={(e) => setFormState({ ...formState, clubId: e.target.value })}
              >
                {clubs.map((club) => (
                  <MenuItem key={club.id} value={club.id.toString()}>
                    {club.name}
                  </MenuItem>
                ))}
              </TextField>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>Cancel</Button>
              <Button variant="contained" color="success" onClick={handleSave}>
                {currentVenue ? "Update" : "Add"}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </AdminLayout>
  );
};

export default VenueList;
