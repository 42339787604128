import React, { useEffect, useState } from "react";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Typography,
  Snackbar,
  TextField,
  CircularProgress,
  Backdrop,
  Grid,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { AdminLayout } from "../../../layouts/AdminLayout";
import BreadCrumbs from "../../../components/Breadcrumbs";
import { apiClient } from "../../../utilities/apiClient";
import { PlayerData, statusOptions, TeamPlayer } from "../../../utilities/types";

const TeamView: React.FC = () => {
  const navigate = useNavigate();
  const { teamId } = useParams<{ teamId: string }>();

  const [teamPlayers, setTeamPlayers] = useState<TeamPlayer[]>([]);
  const [selectedTeamPlayer, setSelectedTeamPlayer] = useState<TeamPlayer | null>(null);
  const [editDialog, setEditDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(true);
 
  useEffect(() => {
    apiClient.get(`/team-players/team/${teamId}`).then((res) => {
      setTeamPlayers(res.data);
      setLoading(false);
    }).catch(() => setLoading(false));
  }, [teamId]);

  const fetchTeamPlayers = async () => {
    try {
      const response = await apiClient.get(`/team-players/team/${teamId}`);
      setTeamPlayers(response.data);
    } catch (error) {
      console.error("Error fetching team players:", error);
    }
  };

  const columns: MRT_ColumnDef<TeamPlayer>[] = [
    { accessorKey: "player.name", header: "First Name" },
    { accessorKey: "player.lastName", header: "Last Name" },
    { accessorKey: "player.email", header: "Email" },
    { accessorKey: "teamPlayerStatus", header: "Team Player Status" },
  ];

  const onEditStatus = (player: TeamPlayer) => {
    setSelectedTeamPlayer(player);
    setEditDialog(true);
  };

  const onSaveStatus = async () => {
    if (selectedTeamPlayer) {
      try {
        await apiClient.put(`/team-players/${selectedTeamPlayer.id}`, {
          id: selectedTeamPlayer.id,
          team: selectedTeamPlayer.team,
          playerId: selectedTeamPlayer.player.id,
          teamPlayerStatus: selectedTeamPlayer.teamPlayerStatus,
        });
        setSnackbarMessage("Status updated successfully");
        setSnackbarOpen(true);
        fetchTeamPlayers();
        setEditDialog(false);
      } catch (error) {
        console.error("Error updating status:", error);
        setSnackbarMessage("Error updating status");
        setSnackbarOpen(true);
      }
    }
  };

  if (loading) {
    return (
      <AdminLayout>
        <Backdrop open={loading} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <BreadCrumbs />
      <Box sx={{ backgroundColor: "white", padding: 4, borderRadius: 2 }}>
        <Typography variant="h4" gutterBottom sx={{ color: "#2e7d32", fontWeight: "bold" }}>
          Team Players for {teamPlayers.length > 0 ? teamPlayers[0].team.name : ""}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", marginBottom: 2 }}>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#4caf50", color: "white", '&:hover': { backgroundColor: "#388e3c" } }}
            onClick={() => navigate(`/admin/teams/${teamId}/add-players`)}
          >
            Add Players To Team Roster
          </Button>
        </Box>
        <MaterialReactTable
          columns={columns}
          data={teamPlayers}
          enableRowActions
          renderRowActions={({ row }) => (
            <Box sx={{ display: "flex", gap: 2, p: 1 }}>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#388e3c", color: "white" }}
              onClick={() => onEditStatus(row.original)}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#388e3c", color: "white" }}
              onClick={() => navigate(`/admin/player/${row.original.player.id}`)}
            >
              View
            </Button>
          </Box>
          )}
        />
        <Dialog open={editDialog} onClose={() => setEditDialog(false)}>
          <DialogTitle>Edit Team Player Status</DialogTitle>
          <DialogContent>
            {selectedTeamPlayer && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}><TextField label="Team Name" value={selectedTeamPlayer.team.name} fullWidth disabled margin="dense"/></Grid>
                <Grid item xs={12} sm={6}><TextField label="Player Name" value={`${selectedTeamPlayer.player.name} ${selectedTeamPlayer.player.lastName}`} fullWidth disabled margin="dense"/></Grid>
                <Grid item xs={12} sm={6}><TextField label="Email" value={selectedTeamPlayer.player.email} fullWidth disabled margin="dense"/></Grid>
                <Grid item xs={12} sm={6}><TextField label="Contact Number" value={selectedTeamPlayer.player.contactNumber} fullWidth disabled margin="dense"/></Grid>
                <Grid item xs={12}><Select fullWidth value={selectedTeamPlayer.teamPlayerStatus} onChange={(e) => setSelectedTeamPlayer((prev) => prev ? { ...prev, teamPlayerStatus: e.target.value } : null)} margin="dense">{statusOptions.map((status) => (<MenuItem key={status} value={status}>{status}</MenuItem>))}</Select></Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditDialog(false)}>Cancel</Button>
            <Button onClick={onSaveStatus} sx={{ backgroundColor: "#4caf50", color: "white" }}>Save</Button>
          </DialogActions>
        </Dialog>
        <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={() => setSnackbarOpen(false)} message={snackbarMessage} />
      </Box>
    </AdminLayout>
  );
};

export default TeamView;