import React, { useState } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  Snackbar,
} from "@mui/material";
import { useQuery, useMutation } from "@tanstack/react-query";
import { apiClient } from "../../../utilities/apiClient";
import { SelectChangeEvent } from "@mui/material/Select";
import { AdminLayout } from "../../../layouts/AdminLayout";
import BreadCrumbs from "../../../components/Breadcrumbs";

type Region = {
  id: number;
  name: string;
  federation: {
    id: number;
    name: string;
  };
};

type ClubData = {
  id: number;
  name: string;
  shortName: string;
  alternateName: string;
  formedYear: number;
  league: string;
  chairman: string;
  regions: Region;
  website: string;
  facebook: string;
  twitter: string;
  instagram: string;
  youtube: string;
  locked: boolean;
};

const AddClub: React.FC = () => {
  const [formData, setFormData] = useState<ClubData>({
    id: 0,
    name: "",
    shortName: "",
    alternateName: "",
    formedYear: 0,
    league: "",
    chairman: "",
    regions: { id: 0, name: "", federation: { id: 0, name: "" } },
    website: "",
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    locked: false,
  });

  const { data: regions = [], isLoading: loadingRegions } = useQuery<Region[]>({queryKey:
    ["regions"],
    queryFn:async () => {
      const response = await apiClient.get("/regions");
      return response.data;
    }
});

  const addClub = async (newClub: ClubData): Promise<void> => {
    await apiClient.post("/clubs", newClub);
  };

  const mutation = useMutation<void, Error, ClubData>({
    mutationFn: addClub,
    onSuccess: () => {
      resetForm();
      alert("Club added successfully!");
    },
    onError: () => {
      alert("Error adding club");
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === "formedYear" ? Number(value) : value,
    }));
  };

  const handleRegionChange = (event: SelectChangeEvent<number>) => {
    const selectedRegionId = Number(event.target.value);
    const selectedRegion = (regions as Region[]).find(region => region.id === selectedRegionId);
    if (selectedRegion) {
      setFormData(prev => ({ ...prev, regions: selectedRegion }));
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutation.mutate(formData);
  };

  const resetForm = () => {
    setFormData({
      id: 0,
      name: "",
      shortName: "",
      alternateName: "",
      formedYear: 0,
      league: "",
      chairman: "",
      regions: { id: 0, name: "", federation: { id: 0, name: "" } },
      website: "",
      facebook: "",
      twitter: "",
      instagram: "",
      youtube: "",
      locked: false,
    });
  };

  return (
    <AdminLayout>
        <BreadCrumbs/>
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="name"
            label="Club Name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="shortName"
            label="Short Name"
            value={formData.shortName}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="alternateName"
            label="Alternate Name"
            value={formData.alternateName}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="formedYear"
            label="Formed Year"
            type="number"
            value={formData.formedYear}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="league"
            label="League"
            value={formData.league}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="chairman"
            label="Chairman"
            value={formData.chairman}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth required>
            <InputLabel id="region-label">Region</InputLabel>
            <Select
              labelId="region-label"
              name="regions"
              value={formData.regions.id || ""}
              onChange={handleRegionChange}
            >
              {(regions as Region[]).map(region => (
                <MenuItem key={region.id} value={region.id}>
                  {region.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="website"
            label="Website"
            value={formData.website}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            type="submit"
            variant="contained"
            color="success"
            fullWidth
            disabled={mutation.status==="pending" || loadingRegions} 
          >
            {mutation.status==="pending"  ? "Adding..." : "Add Club"} 
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={!!mutation.error}
        onClose={() => mutation.reset()}
        message="Error adding club"
        autoHideDuration={6000}
      />
      <Snackbar
        open={mutation.isSuccess}
        onClose={() => mutation.reset()}
        message="Club added successfully!"
        autoHideDuration={6000}
      />
    </form>
    </AdminLayout>
  );
};

export default AddClub;