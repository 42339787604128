import React from 'react';
import { FrontendLayout } from '../../layouts/FrontendLayout';

export function About() {
  return (
    <FrontendLayout>
      <h1>About Rugby App</h1>
      <p>This app provides insights into rugby games, players, and stats.</p>
    </FrontendLayout>
  );
}