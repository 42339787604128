import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import { apiClient } from "../../../utilities/apiClient";
import { AdminLayout } from "../../../layouts/AdminLayout";
import BreadCrumbs from "../../../components/Breadcrumbs";

interface Skill {
  id: number;
  name: string;
  description: string;
  proficiencyLevel: number;
  minScore: number;
  maxScore: number;
}

const SkillManagement: React.FC = () => {
  const [skills, setSkills] = useState<Skill[]>([]);
  const [formData, setFormData] = useState<Skill>({
    id: 0,
    name: "",
    description: "",
    proficiencyLevel: 0,
    minScore: 0,
    maxScore: 0,
  });

  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchSkills();
  }, []);

  const fetchSkills = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get("/skills");
      setSkills(response.data);
    } catch (error) {
      console.error("Error fetching skills:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (editing) {
        await apiClient.put(`/skills/${formData.id}`, formData);
      } else {
        await apiClient.post("/skills", formData);
      }
      fetchSkills();
      handleCloseDialog();
    } catch (error) {
      console.error("Error saving skill:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (skill: Skill) => {
    setFormData(skill);
    setEditing(true);
    setOpenDialog(true);
  };

  const handleAdd = () => {
    setFormData({ id: 0, name: "", description: "", proficiencyLevel: 0, minScore: 0, maxScore: 0 });
    setEditing(false);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
  <AdminLayout>
    <BreadCrumbs/>
      <Box sx={{ p: 3 }}>
      <Typography variant="h5">Skill Management</Typography>

      <Button variant="contained" color="success" sx={{ mt: 2, mb: 2 }} onClick={handleAdd}>
        Add Skill
      </Button>

      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <CircularProgress />
        </Box>
      )}

      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Proficiency Level</TableCell>
              <TableCell>Min Score</TableCell>
              <TableCell>Max Score</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {skills.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((skill) => (
              <TableRow key={skill.id}>
                <TableCell>{skill.id}</TableCell>
                <TableCell>{skill.name}</TableCell>
                <TableCell>{skill.description}</TableCell>
                <TableCell>{skill.proficiencyLevel}</TableCell>
                <TableCell>{skill.minScore}</TableCell>
                <TableCell>{skill.maxScore}</TableCell>
                <TableCell>
                  <Button onClick={() => handleEdit(skill)}>Edit</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={skills.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>{editing ? "Edit Skill" : "Add Skill"}</DialogTitle>
        <DialogContent>
          <TextField fullWidth margin="dense" label="Name" name="name" value={formData.name} onChange={handleChange} required />
          <TextField fullWidth margin="dense" label="Description" name="description" value={formData.description} onChange={handleChange} />
          <TextField fullWidth margin="dense" label="Proficiency Level" name="proficiencyLevel" type="number" value={formData.proficiencyLevel} onChange={handleChange} />
          <TextField fullWidth margin="dense" label="Min Score" name="minScore" type="number" value={formData.minScore} onChange={handleChange} />
          <TextField fullWidth margin="dense" label="Max Score" name="maxScore" type="number" value={formData.maxScore} onChange={handleChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="success" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : editing ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  </AdminLayout>
  );
};

export default SkillManagement;
