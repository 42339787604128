// API Base URL
export const BASE_URL= 'http://127.0.0.1:8004/'
export const BASE_URL_LIVE= 'https://www.mitambo.co.zw'
export const POSITION_TYPES = ["FORWARD", "BACK"];
export const POSITIONS = [
  "PROP",
  "HOOKER",
  "LOCK",
  "OPENSIDE_FLANK",
  "BLINDSIDE_FLANK",
  "EIGHTSMEN",
  "SCRUMHALF",
  "FLYHALF",
  "LEFT_WING",
  "INSIDE_CENTER",
  "OUTSIDE_CENTER",
  "RIGHT_WING",
  "FULLBACK",
];
