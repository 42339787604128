import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  TablePagination,
  MenuItem,
  Checkbox,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { PlayerRegister, PlayerData, Team, TeamPlayer } from "../../../../utilities/types";
import { apiClient } from "../../../../utilities/apiClient";
import BreadCrumbs from "../../../../components/Breadcrumbs";
import { AdminLayout } from "../../../../layouts/AdminLayout";

const PlayerRegisterManagement: React.FC = () => {
  const [playerRegisters, setPlayerRegisters] = useState<PlayerRegister[]>([]);
  const [playerTeam, setPlayersByTeam] = useState<TeamPlayer[]>([]);
  const [teams, setTeam] = useState<Team[]>([]);
  const [selectedPlayers, setSelectedPlayers] = useState<number[]>([]);
  const [formData, setFormData] = useState<PlayerRegister>({
    id: 0,
    title: "",
    created: new Date().toISOString(),
    player: { id: 0, name: "", middleName: "", lastName: "", bio: "", gender: "", contactNumber: "", dob: "", email: "", playerStatus: "", province: "", fileName: "" },
  });
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [teamId, setTeamId] = useState<number | null>(null);

  useEffect(() => {
    fetchPlayerRegisters();
    fetchTeams();
    if (teamId) fetchPlayersByTeam(teamId);
  }, [teamId]);

  const fetchPlayerRegisters = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get("/player-register");
      setPlayerRegisters(response.data);
    } catch (error) {
      console.error("Error fetching player registers:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTeams = async () => {
    try {
      const response = await apiClient.get("/teams");
      setTeam(response.data);
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  };

  const fetchPlayersByTeam = async (teamId: number) => {
    try {
      const response = await apiClient.get(`/team-players/team/${teamId}`);
      setPlayersByTeam(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching players by team:", error);
      setPlayersByTeam([]);
    }
  };

  const handlePlayerSelectionChange = (playerId: number) => {
    setSelectedPlayers((prevSelectedPlayers) =>
      prevSelectedPlayers.includes(playerId)
        ? prevSelectedPlayers.filter((id) => id !== playerId)
        : [...prevSelectedPlayers, playerId]
    );
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const updatedFormData = {
        ...formData,
        players: playerTeam.filter((player) => selectedPlayers.includes(player.id)),
      };
      editing ? await apiClient.put(`/player-register/${formData.id}`, updatedFormData) : await apiClient.post("/player-register", updatedFormData);
      fetchPlayerRegisters();
      setOpenDialog(false);
    } catch (error) {
      console.error("Error saving player register:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AdminLayout>
      <BreadCrumbs />
      <Box sx={{ p: 3 }}>
        <Typography variant="h5">Player Register</Typography>

        <TextField select label="Select Team" value={teamId || ""} onChange={(e) => setTeamId(Number(e.target.value))} fullWidth sx={{ mb: 2 }}>
          {teams.map((team) => (
            <MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>
          ))}
        </TextField>

        <Typography variant="h6">Register those Present</Typography>
        <List>
          {playerTeam.map((ply) => (
            <ListItem key={ply.id} sx={{ display: 'flex', alignItems: 'center' }}>
              <ListItemText primary={`${ply.player.name} ${ply.player.middleName} ${ply.player.lastName}`} />
              <Checkbox checked={selectedPlayers.includes(ply.id)} onChange={() => handlePlayerSelectionChange(ply.id)} />
            </ListItem>
          ))}
        </List>

    
      </Box>
    </AdminLayout>
  );
};

export default PlayerRegisterManagement;
