import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  TextField,
  MenuItem,
  Grid,
  Avatar,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {  BASE_URL, BASE_URL_LIVE, POSITION_TYPES, POSITIONS } from "../../../utilities/constants";
import { BioDataItem } from "../../../utilities/types";
import { apiClient } from "../../../utilities/apiClient";
import { AdminLayout } from "../../../layouts/AdminLayout";
import BreadCrumbs from "../../../components/Breadcrumbs";

const steps = ["Create Player", "Add Positions", "Add Height and Weight", "Add Education"];
const CreatePlayerWizard: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [playerId, setPlayerId] = useState<number | null>(null);
  // Player form data (Step 0)
  const [playerData, setPlayerData] = useState({
    name: "",
    middleName: "",
    lastName: "",
    bio: "",
    gender: "",
    contactNumber: "",
    dob: "",
    email: "",
    playerStatus: "active",
    province: "",
    fileName: "",
  });
  const [playerFile, setPlayerFile] = useState<File | null>(null);
  const [playerPreview, setPlayerPreview] = useState<string | null>(null);

  // Position form data (Step 1)
  const [positionData, setPositionData] = useState({
    position: "",
    positionType: "",
    primary: true,
  });
 

  // Bio Data (Steps 2 & 3)
  const [bioData, setBioData] = useState<BioDataItem>({
    height: 0, 
    weight: 0,  
    period: "PRESEASON",  
    captured: new Date().toISOString().split("T")[0],
    playerId:0
  });

  // Education data (Step 4)
  const [educationData, setEducationData] = useState({
    institution: "",
    level: "",
    degree: "",
    fieldOfStudy: "",
    startDate: "",
    endDate: "",
  });
  const [multipleEducation, setMultipleEducation] = useState(false);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // Handlers
  const handlePlayerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPlayerData((prev) => ({ ...prev, [name]: value }));
  };

  const handleBioChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: "height" | "weight"
  ) => {
    const value = parseFloat(e.target.value) || 0;
    setBioData((prev) => ({ ...prev, [field]: value }));
  };
  

  const handlePlayerFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const f = e.target.files?.[0] || null;
    setPlayerFile(f);
    setPlayerData((prev) => ({ ...prev, fileName: f?.name || "" }));
    setPlayerPreview(f ? URL.createObjectURL(f) : null);
  };

  const handlePositionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPositionData((prev) => ({ ...prev, [name]: value }));
  };

 
 


  const handleEducationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEducationData((prev) => ({ ...prev, [name]: value }));
  };

  const handleComplete = () => {
    queryClient.invalidateQueries({ queryKey: ["players"] });
  };

  // Mutations (same as before)

  const createPlayerMutation = useMutation({
    mutationFn: async () => {
      const form = new FormData();
      form.append("request", JSON.stringify({ ...playerData }));
      if (playerFile) form.append("file", playerFile);

      const res = await apiClient.post(`/players/`, form, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return res.data;
    },
    onSuccess: (data) => {
      setPlayerId(data.id);
      setActiveStep((prev) => prev + 1);
    },
    onError: () => {
      alert("Failed to create player.");
    },
  });

  const createPositionMutation = useMutation({
    mutationFn: async () => {
      if (!playerId) throw new Error("Player ID not set");

      const requestData = {
        id: 0,
        position: positionData.position,
        positionType: positionData.positionType,
        player: {
          id: playerId,
          ...playerData,
          fileName: playerFile?.name || "",
        },
        primary: positionData.primary,
      };

      const response = await apiClient.post(`$/player-position`, requestData);
      return response.data;
    },
    onSuccess: () => setActiveStep((prev) => prev + 1),
    onError: (error) => {
      console.error("Failed to add position:", error);
      alert("Failed to add position.");
    },
  });
  const createBioDataMutation = useMutation({
    mutationFn: async () => {
      if (!playerId) throw new Error("Player ID not set");
      const payload: BioDataItem = {
        weight: bioData.weight,
        height: bioData.height,
        period: bioData.period,
        captured: bioData.captured ,
        playerId
      };
      console.log("Sending bio playerId :", playerId);
      console.log("Sending bio data payload:", payload);
  
      await axios.post(`${BASE_URL_LIVE}/player-bio-data`, payload, {
        headers: { "Content-Type": "application/json" },
      });
    },
    onSuccess: () => {
      setActiveStep((prev) => prev + 1); // Move to next step
    },
    onError: (error: any) => {
      alert(`Error creating bio data: ${(error as Error).stack}`);
    },
  });
  
  const createEducationMutation = useMutation({
    mutationFn: async () => {
      if (!playerId) throw new Error("Player ID not set");

      const payload = {
        institution: educationData.institution,
        level: educationData.level,
        startDate: educationData.startDate,
        endDate: educationData.endDate,
        degree: educationData.degree,
        fieldOfStudy: educationData.fieldOfStudy,
        player: {
          id: playerId,
          name: playerData.name,
          middleName: playerData.middleName,
          lastName: playerData.lastName,
          bio: playerData.bio,
          gender: playerData.gender,
          contactNumber: playerData.contactNumber,
          dob: playerData.dob,
          email: playerData.email,
          playerStatus: playerData.playerStatus,
          province: playerData.province,
          fileName: playerData.fileName,
        },
      };

      console.log("Sending education payload:", JSON.stringify(payload));

      await apiClient.post(`/player-education/`, payload, {
        headers: { "Content-Type": "application/json" },
      });
    },
    onSuccess: () => {
      alert("Education record added.");
      if (multipleEducation) {
        setEducationData({
          institution: "",
          level: "",
          degree: "",
          fieldOfStudy: "",
          startDate: "",
          endDate: "",
        });
      } else {
        // Final step complete, show details
        handleComplete();
        setActiveStep((prev) => prev + 1);
      }
    },
    onError: (error: any) => {
      alert(error.message);
    },
  });

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        createPlayerMutation.mutate();
        break;
      case 1:
        createPositionMutation.mutate();
        break;
      case 2:
        createBioDataMutation.mutate();
        break;
      case 4:
        createEducationMutation.mutate();
        break;
    }
  };

  const isNextDisabled = () => {
    switch (activeStep) {
      case 0:
        return !playerData.name || createPlayerMutation.status === "pending";
      case 1:
        return !positionData.position || createPositionMutation.status === "pending";
      case 2:
        return createBioDataMutation.status === "pending";
      case 3:
        return !educationData.institution || createEducationMutation.status === "pending";
      default:
        return false;
    }
  };

  return (
 <AdminLayout>
  <BreadCrumbs/>
  <Box p={3}>
      <Paper elevation={3} sx={{ p: 3, maxWidth: 600, margin: "auto" }}>
        <Typography variant="h4" gutterBottom>
          Create Player Wizard
        </Typography>

        {activeStep === steps.length ? (
         // All steps done: navigate to the player details view using playerId
         (playerId ? (
          // Navigate to /player-details/:id
          navigate(`/player-details/${playerId}`),
          null // We return null here because navigate will move away from this page
        ) : (
          <Typography color="error">
            No player ID available. Unable to show player details.
          </Typography>
        ))
      ): (
          <>
            <Typography variant="subtitle1" gutterBottom>
              Step {activeStep + 1} of {steps.length}: {steps[activeStep]}
            </Typography>

            {/* Step content */}
            {activeStep === 0 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="name"
                    value={playerData.name}
                    onChange={handlePlayerChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Middle Name"
                    name="middleName"
                    value={playerData.middleName}
                    onChange={handlePlayerChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    value={playerData.lastName}
                    onChange={handlePlayerChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Bio"
                    name="bio"
                    value={playerData.bio}
                    onChange={handlePlayerChange}
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    select
                    label="Gender"
                    name="gender"
                    value={playerData.gender}
                    onChange={handlePlayerChange}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Contact Number"
                    name="contactNumber"
                    value={playerData.contactNumber}
                    onChange={handlePlayerChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="date"
                    label="Date of Birth"
                    name="dob"
                    value={playerData.dob}
                    onChange={handlePlayerChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={playerData.email}
                    onChange={handlePlayerChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    select
                    label="Player Status"
                    name="playerStatus"
                    value={playerData.playerStatus}
                    onChange={handlePlayerChange}
                  >
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Province"
                    name="province"
                    value={playerData.province}
                    onChange={handlePlayerChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" mb={1}>
                    Upload Profile Image
                  </Typography>
                  <input type="file" accept="image/*" onChange={handlePlayerFileChange} />
                  {playerPreview && (
                    <Box mt={2} display="flex" justifyContent="center">
                      <Avatar src={playerPreview} alt="Preview" sx={{ width: 100, height: 100 }} />
                    </Box>
                  )}
                </Grid>
              </Grid>
            )}

            {activeStep === 1 && (
              <>
                <Typography variant="h6" gutterBottom>
                  Add Positions
                </Typography>
                <TextField
                  fullWidth
                  margin="dense"
                  select
                  label="Position Type"
                  name="positionType"
                  value={positionData.positionType}
                  onChange={handlePositionChange}
                >
                  {POSITION_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  margin="dense"
                  select
                  label="Position"
                  name="position"
                  value={positionData.position}
                  onChange={handlePositionChange}
                >
                  {POSITIONS.map((pos) => (
                    <MenuItem key={pos} value={pos}>
                      {pos}
                    </MenuItem>
                  ))}
                </TextField>
         
              </>
            )} 
            {activeStep === 2 && (
  <>
    <Typography variant="h6" gutterBottom>
      Add Height and Weight
    </Typography>
    <TextField
      label="Height (cm)"
      type="number"
      fullWidth
      margin="dense"
      value={bioData.height || ""}
      onChange={(e) => handleBioChange(e, "height")}
      InputProps={{ inputProps: { min: 0 } }}
    />
    <TextField
      label="Weight (kg)"
      type="number"
      fullWidth
      margin="dense"
      value={bioData.weight || ""}
      onChange={(e) => handleBioChange(e, "weight")}
      InputProps={{ inputProps: { min: 0 } }}
    />
  </>
)}

            {activeStep === 3 && (
              <>
                <Typography variant="h6" gutterBottom>
                  Add Education
                </Typography>
                <TextField
                  label="Institution"
                  fullWidth
                  margin="dense"
                  name="institution"
                  value={educationData.institution}
                  onChange={handleEducationChange}
                />
                <TextField
                  label="Level"
                  fullWidth
                  margin="dense"
                  name="level"
                  value={educationData.level}
                  onChange={handleEducationChange}
                />
                <TextField
                  label="Degree"
                  fullWidth
                  margin="dense"
                  name="degree"
                  value={educationData.degree}
                  onChange={handleEducationChange}
                />
                <TextField
                  label="Field Of Study"
                  fullWidth
                  margin="dense"
                  name="fieldOfStudy"
                  value={educationData.fieldOfStudy}
                  onChange={handleEducationChange}
                />
                <TextField
                  label="Start Date"
                  type="date"
                  fullWidth
                  margin="dense"
                  name="startDate"
                  value={educationData.startDate}
                  onChange={handleEducationChange}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="End Date"
                  type="date"
                  fullWidth
                  margin="dense"
                  name="endDate"
                  value={educationData.endDate}
                  onChange={handleEducationChange}
                  InputLabelProps={{ shrink: true }}
                />

                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setMultipleEducation(true);
                      createEducationMutation.mutate();
                    }}
                    disabled={educationData.institution === "" || createEducationMutation.status === "pending"}
                  >
                    {createEducationMutation.status === "pending" ? "Saving..." : "Add Another Education"}
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ ml: 2 }}
                    onClick={() => {
                      setMultipleEducation(false);
                      createEducationMutation.mutate();
                    }}
                    disabled={educationData.institution === "" || createEducationMutation.status === "pending"}
                  >
                    {createEducationMutation.status === "pending" ? "Saving..." : "Complete"}
                  </Button>
                </Box>
              </>
            )}

            {activeStep < steps.length - 1 && (
              <Box mt={3} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={isNextDisabled()}
                >
                  {createPlayerMutation.status === "pending" ||
                  createPositionMutation.status === "pending" ||
                  createBioDataMutation.status === "pending" ||
                  createEducationMutation.status === "pending"
                    ? "Saving..."
                    : "Next"}
                </Button>
              </Box>
            )}
          </>
        )}
      </Paper>
    </Box>
  );
 </AdminLayout>
)};

export default CreatePlayerWizard;
