
import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, Typography, CircularProgress } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { apiClient } from '../../../../../utilities/apiClient';

interface InjuryForm {
  type: string;
  severity: string;
  affectedArea: string;
  dateOfInjury: string;
}

export function InjuryRecordsTab({ playerId }: { playerId: number }) {
  const [injuryData, setInjuryData] = useState<{ [key: string]: any }[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [modalForm, setModalForm] = useState<InjuryForm>({
    type: '',
    severity: '',
    affectedArea: '',
    dateOfInjury: '',
  });

  useEffect(() => {
    const fetchInjuryData = async () => {
      try {
        const response = await apiClient.get(`/injury/player/${playerId}`);
        setInjuryData(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching injury data:', err);
        setError('Failed to fetch injury data. Please try again later.');
        setLoading(false);
      }
    };

    fetchInjuryData();
  }, [playerId]);

  const openAddModal = () => setOpenModal(true);
  const closeAddModal = () => {
    setOpenModal(false);
    setModalForm({
      type: '',
      severity: '',
      affectedArea: '',
      dateOfInjury: '',
    });
  };

  const handleAddRecord = async () => {
    if (!modalForm.type || !modalForm.severity || !modalForm.affectedArea) {
      alert('Please fill in all fields.');
      return;
    }

    const newRecord = {
      ...modalForm,
      player: {
        id: playerId,
      }
    };

    try {
      const response = await apiClient.post('/player-injury/', newRecord);
      setInjuryData((prev) => [...prev, response.data]); // Add new record from response
      closeAddModal();
    } catch (error) {
      console.error('Error adding injury record:', error);
      alert('Failed to add injury record. Please try again.');
    }
  };

  const columns: GridColDef[] = [
    { field: 'type', headerName: 'Injury Type', width: 200 },
    { field: 'severity', headerName: 'Severity', width: 150 },
    { field: 'affectedArea', headerName: 'Affected Area', width: 200 },
    { field: 'dateOfInjury', headerName: 'Date of Injury', width: 150 },
  ];

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    );
  }

  return (
    <Box>
      <Button variant="contained" color="success" onClick={openAddModal}>
        Add New Injury Record
      </Button>
      <Box sx={{ height: 400, width: '100%', mt: 2 }}>
        <DataGrid
          rows={injuryData.map((row, index) => ({ ...row, id: index }))}
          columns={columns}
          paginationModel={{ page: 0, pageSize: 5 }}
        />
      </Box>

      <Modal open={openModal} onClose={closeAddModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            width: 400,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" mb={2}>
            Add New Injury Record
          </Typography>
          <TextField
            label="Injury Type"
            fullWidth
            margin="normal"
            onChange={(e) => setModalForm((prev) => ({ ...prev, type: e.target.value }))}
          />
          <TextField
            label="Severity"
            fullWidth
            margin="normal"
            onChange={(e) => setModalForm((prev) => ({ ...prev, severity: e.target.value }))}
          />
          <TextField
            label="Affected Area"
            fullWidth
            margin="normal"
            onChange={(e) => setModalForm((prev) => ({ ...prev, affectedArea: e.target.value }))}
          />
          <TextField
            label="Date of Injury"
            type="date"
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setModalForm((prev) => ({ ...prev, dateOfInjury: e.target.value }))}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="outlined" onClick={closeAddModal} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button variant="contained" color="success" onClick={handleAddRecord}>
              Add
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
