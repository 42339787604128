/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { Team } from '../pages/content/Team';
import { FrontendLayout } from '../layouts/FrontendLayout';
import { Header } from './Header';
 

 
export const TeamPages = () => {

// const teams = useTeamsItems();
 

    return (
        <>
            
            <FrontendLayout>
        <Header/>
   

 
          </FrontendLayout>
        </>
    );
};

 