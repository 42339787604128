import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, Typography, CircularProgress, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { apiClient } from '../../../../../utilities/apiClient';
import { AssessmentType } from '../../../../../utilities/types';

export function StatisticsTab({ playerId }: { playerId: number }) {
  const [statistics, setStatistics] = useState<{ [key: string]: any }[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [assessmentTypes, setAssessmentTypes] = useState<AssessmentType[]>([]);
  const [newAssessment, setNewAssessment] = useState({
    playerId,
    assessmentTypeId: '',
    rating: '',
    comments: '',
    assessor: '',
    assessmentDate: new Date().toISOString()
  });

  // Fetch Player Assessments
  const fetchPlayerAssessment = async () => {
    try {
      const response = await apiClient.get(`/player-assessment/player-id/${playerId}`);
      setStatistics(response.data);
    } catch (err) {
      console.error('Error fetching statistics:', err);
      setError('Failed to fetch assessments. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const fetchAssessmentTypes = async () => {
    try {
      const response = await apiClient.get('/assessment-type');
      setAssessmentTypes(response.data);
    } catch (err) {
      console.error('Error fetching assessment types:', err);
    }
  };

  useEffect(() => {
    fetchPlayerAssessment();
    fetchAssessmentTypes();
  }, [playerId]);

  const handleAddAssessment = async () => {
    try {
      await apiClient.post('/player-assessment', newAssessment);
      fetchPlayerAssessment();
      setOpenModal(false);
    } catch (err) {
      console.error('Error adding assessment:', err);
    }
  };

  // Define Table Columns with Conditional Styling
  const columns: GridColDef[] = [
    { field: 'assessmentType', headerName: 'Assessment Type', width: 200, valueGetter: (params: AssessmentType) => params.name },
    { 
      field: 'rating', 
      headerName: 'Rating', 
      width: 100,
      cellClassName: (params) => {
        const rating = params.row?.rating;
        const minScore = params.row?.assessmentType?.skill?.minScore;
        if (rating === undefined || minScore === undefined) return '';
        if (rating < minScore) return 'low-score';
        if (rating > minScore) return 'high-score';
        if (rating === minScore) return 'equal-score';
        return '';
      }
    },
    { field: 'assessor', headerName: 'Assessor', width: 150 },
    { field: 'comments', headerName: 'Comments', width: 200 },
    // { field: 'assessmentDate', headerName: 'Assessment Date', width: 200}
  ];

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography variant="h6" color="error">{error}</Typography>;
  }

  return (
    <Box>
      <Typography variant="h5" gutterBottom>Player Assessments</Typography>
      <Button variant="contained" color="primary" onClick={() => setOpenModal(true)}>Add Assessment</Button>
      <Box sx={{ height: 400, width: '100%', mt: 2 }}>
        <DataGrid
          rows={statistics.map((row, index) => ({ ...row, id: index }))}
          columns={columns}
          sx={{
            '& .low-score': { color: 'red', fontWeight: 'bold' },
            '& .high-score': { color: 'green', fontWeight: 'bold' },
            '& .equal-score': { color: 'orange', fontWeight: 'bold' }
          }}
          paginationModel={{ page: 0, pageSize: 5 }}
        />
      </Box>

      {/* Modal for Adding Assessment */}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box p={4} bgcolor="white" sx={{ width: 400, mx: 'auto', mt: 10, borderRadius: 2 }}>
          <Typography variant="h6">Add New Assessment</Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel>Assessment Type</InputLabel>
            <Select value={newAssessment.assessmentTypeId} onChange={(e) => setNewAssessment({ ...newAssessment, assessmentTypeId: e.target.value })}>
              {assessmentTypes.map((type) => (
                <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField fullWidth label="Rating" margin="normal" value={newAssessment.rating} onChange={(e) => setNewAssessment({ ...newAssessment, rating: e.target.value })} />
          <TextField fullWidth label="Comments" margin="normal" value={newAssessment.comments} onChange={(e) => setNewAssessment({ ...newAssessment, comments: e.target.value })} />
          <TextField fullWidth label="Assessor" margin="normal" value={newAssessment.assessor} onChange={(e) => setNewAssessment({ ...newAssessment, assessor: e.target.value })} />
          <Button variant="contained" color="primary" fullWidth onClick={handleAddAssessment} sx={{ mt: 2 }}>Submit</Button>
        </Box>
      </Modal>
    </Box>
  );
}