import { jwtDecode } from "jwt-decode";

export const isTokenExpired = (token:string) => {
    if (!token) return true; // No token means it's expired or invalid

    try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return decoded && decoded.exp ? decoded.exp < currentTime : true;
    } catch (error) {
        return true; 
    }
};
