import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  SelectChangeEvent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Backdrop,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import { Assessment, Skill } from "../../../utilities/types";
import { apiClient } from "../../../utilities/apiClient";
import { AdminLayout } from "../../../layouts/AdminLayout";
import BreadCrumbs from "../../../components/Breadcrumbs";

function AssessmentManagement (){
  const [assessments, setAssessments] = useState<Assessment[]>([]);
  const [skills, setSkills] = useState<Skill[]>([]);
  const [formData, setFormData] = useState<Assessment>({
    id: 0,
    name: "",
    description: "",
    instruction: "",
    skill: { id: 0, name: "", description: "", proficiencyLevel: 0, minScore: 0, maxScore: 0 },
  });

  const [editing, setEditing] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  
  useEffect(() => {
    setLoading(true);
Promise.all([fetchAssessments(), fetchSkills()])
.finally(() => setLoading(false));
  }, []);

  const fetchAssessments = async () => {
    try {
      const response = await apiClient.get("/assessment-type");
      setAssessments(response.data);
    } catch (error) {
      console.error("Error fetching assessments:", error);
    }
  };

  const fetchSkills = async () => {
    try {
      const response = await apiClient.get("/skills");
      setSkills(response.data);
    } catch (error) {
      console.error("Error fetching skills:", error);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: any }>) => {
    setFormData({ ...formData, [e.target.name as string]: e.target.value });
  };

  const handleSkillChange = (e: SelectChangeEvent<number>) => {
    const selectedSkill = skills.find((skill) => skill.id === Number(e.target.value));
    setFormData({ ...formData, skill: selectedSkill || formData.skill });
  };

  const handleSubmit = async () => {
    try {
      if (editing) {
        await apiClient.put(`/assessment-type/${formData.id}`, formData);
      } else {
        await apiClient.post("/assessment-type", formData);
      }
      fetchAssessments();
      handleCloseDialog();
    } catch (error) {
      console.error("Error saving assessment:", error);
    }
  };

  const handleEdit = (assessment: Assessment) => {
    setFormData(assessment);
    setEditing(true);
    setOpenDialog(true);
  };

  const handleAdd = () => {
    setFormData({ id: 0, name: "", description: "", instruction: "", skill: { id: 0, name: "", description: "", proficiencyLevel: 0, minScore: 0, maxScore: 0 } });
    setEditing(false);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
   <AdminLayout>
        <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    <BreadCrumbs/>
     <Box sx={{ p: 3 }}>
      <Typography variant="h5">Assessment Management</Typography>

      {/* Add Button */}
      <Button variant="contained" color="success" sx={{ mt: 2, mb: 2 }} onClick={handleAdd}>
        Add Assessment
      </Button>

      {/* Assessment Table */}
      <TableContainer component={Paper} sx={{ mt: 4 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Instruction</TableCell>
            <TableCell>Skill</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assessments
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((assessment) => (
              <TableRow key={assessment.id}>
                <TableCell>{assessment.id}</TableCell>
                <TableCell>{assessment.name}</TableCell>
                <TableCell>{assessment.description}</TableCell>
                <TableCell>{assessment.instruction}</TableCell>
                <TableCell>{assessment.skill.name}</TableCell>
                <TableCell>
                  <Button onClick={() => handleEdit(assessment)}>Edit</Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={assessments.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>

      {/* Dialog for Adding/Editing Assessments */}
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>{editing ? "Edit Assessment" : "Add Assessment"}</DialogTitle>
        <DialogContent>
          <TextField fullWidth margin="dense" label="Name" name="name" value={formData.name} onChange={handleChange} required />
          <TextField fullWidth margin="dense" label="Description" name="description" value={formData.description} onChange={handleChange} />
          <TextField fullWidth margin="dense" label="Instruction" name="instruction" value={formData.instruction} onChange={handleChange} />

          {/* Skill Selection */}
          <Select fullWidth value={formData.skill.id} onChange={handleSkillChange} displayEmpty>
            <MenuItem value={0} disabled>Select a Skill</MenuItem>
            {skills.map((skill) => (
              <MenuItem key={skill.id} value={skill.id}>
                {skill.name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="success">
            {editing ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
   </AdminLayout>  );
};

export default AssessmentManagement;
