import React from 'react'; 
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';

interface FrontendLayoutProps {
  children: React.ReactNode; 
}

export const FrontendLayout: React.FC<FrontendLayoutProps> = ({ children }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>

      <Header />
      <main style={{ flexGrow: 1, padding: '0px' }}>
        {children}
      </main>
      <Footer />
    </div>
  );
};
