import React from 'react';
import { FrontendLayout } from '../../layouts/FrontendLayout';
 

 


export const Sponsors = () => {

     return (
        <>    
        <FrontendLayout> 
        <div className="bottom-wrapper">
            <span>Sponsors Here</span>
            </div>

        </FrontendLayout>
  
</>

  
    );
};

 