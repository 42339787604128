import { FrontendLayout } from "../../layouts/FrontendLayout"

 


 
export const Team = () => {
  return (
    <>    
    <FrontendLayout> 
    <div className="bottom-wrapper">
    
        </div>

    </FrontendLayout>

</>
  );
};

 