 
import axios from 'axios';
import {  BASE_URL_LIVE } from './constants';

// Function to get the token from localStorage
const getAuthToken = () => {
  return localStorage.getItem('authToken'); // Fetch token from localStorage
};

// Create a single axios instance
export const apiClient = axios.create({
  baseURL: BASE_URL_LIVE, // Base URL for all requests
});

// Add an authorization interceptor to the single client
apiClient.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Optional: Add a response interceptor to handle token expiry or unauthorized access
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle unauthorized access (e.g., redirect to login)
      console.error('Unauthorized access');
      localStorage.removeItem('authToken'); // Clear the token
      window.location.href = '/login'; // Redirect to login page
    }
    return Promise.reject(error);
  }
);