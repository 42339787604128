import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import { apiClient } from '../../../utilities/apiClient';
import { Club } from '../../../utilities/types';
import {AdminLayout} from '../../../layouts/AdminLayout';

// Define the TeamData type
interface TeamData {
  name: string;
  club: Club;
  competition: Competition;
}

// Define the expected response type for the added team
interface TeamResponse {
  id: number;
  name: string;
  club: {
    id: number;
    name: string;
    shortName: string;
    alternateName: string;
    formedYear: number;
    league: string;
    chairman: string;
    website: string;
    facebook: string;
    twitter: string;
    instagram: string;
    youtube: string;
    locked: boolean;
  };
  competition: {
    id: number;
    name: string;
    tier: number;
    hasGroups: boolean;
    active: boolean;
    provincialTeamsOnly: boolean;
    league: boolean;
    cup: boolean;
  };
}

// Define the expected structure of a competition
interface Competition {
  id: number;
  name: string;
  tier: number;
  hasGroups: boolean;
  active: boolean;
  provincialTeamsOnly: boolean;
  league: boolean;
  cup: boolean;
}

// Fetch competitions
const fetchCompetitions = async (): Promise<Competition[]> => {
  const response = await apiClient.get('/competition');
  return response.data;
};

// Fetch club details
const fetchClubDetails = async (clubId: number) => {
  const response = await apiClient.get(`/clubs/${clubId}`);
  return response.data;
};

// Add team
const addTeam = async (teamData: TeamData): Promise<TeamResponse> => {
  const response = await apiClient.post('/teams', teamData, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};

const AddTeam: React.FC = () => {
  const { clubId } = useParams<{ clubId: string }>();
  const [teamName, setTeamName] = useState('');
  const [competitionId, setCompetitionId] = useState('');
  const [, setAddedTeam] = useState<TeamResponse | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [clubDetails, setClubDetails] = useState<any>(null); // Adjust type as needed

  // Fetch competitions
  const { data: competitions = [], isLoading: isLoadingCompetitions } = useQuery<Competition[]>({
    queryKey: ['competitions'],
    queryFn: fetchCompetitions,
  });

  // Fetch club details on load
  useEffect(() => {
    const loadClubDetails = async () => {
      if (clubId) {
        const clubData = await fetchClubDetails(Number(clubId));
        setClubDetails(clubData);
      }
    };

    loadClubDetails();
  }, [clubId]);

  const mutation = useMutation<TeamResponse, Error, TeamData>({
    mutationFn: addTeam,
    onSuccess: (data) => {
      setTeamName('');
      setCompetitionId('');
      setAddedTeam(data);
      setSnackbarOpen(true);
    },
    onError: (error) => {
      console.error('Error adding team:', error);
    },
  });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const teamData: TeamData = {
      name: teamName,
      club: clubDetails,
      competition: competitions.find((comp) => comp.id === Number(competitionId)) as Competition,
    };
    console.log('teamData:', JSON.stringify(teamData));
    mutation.mutate(teamData);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (isLoadingCompetitions) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <AdminLayout>
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Add Team for Club: {clubDetails ? clubDetails.name : 'Loading...'}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Team Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
          required
        />
        
        <FormControl fullWidth margin="normal">
          <InputLabel>Competition</InputLabel>
          <Select
            value={competitionId}
            onChange={(e) => setCompetitionId(e.target.value)}
            required
          >
            <MenuItem value=""><em>Select a competition</em></MenuItem>
            {competitions.map((competition) => (
              <MenuItem key={competition.id} value={competition.id}>
                {competition.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        
        <Button
          type="submit"
          variant="contained"
          color="success"
          fullWidth
        
          disabled={mutation.status==='pending'}
        >
          {mutation.status ==='pending'? <CircularProgress size={24} /> : 'Add Team'}
        </Button>
      </form>

      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message="Team added successfully!"
        autoHideDuration={4000}
      />
    </Container>
    </AdminLayout>
  );
};

export default AddTeam;