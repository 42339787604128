import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@mui/material';

const BreadCrumbs = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);
    return (
      <Breadcrumbs 
        aria-label="breadcrumb" 
        sx={{ 
          marginBottom: 2,
          backgroundColor: '#f9f9f9', // Light background
          padding: '8px 16px',
          borderRadius: '4px',
          boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
        }}
      >
        {pathSegments.map((segment, index) => {
          const isLast = index === pathSegments.length - 1;
          const linkPath = `/${pathSegments.slice(0, index + 1).join('/')}`;
  
          return isLast ? (
            <Typography key={linkPath} color="text.primary" fontWeight="bold">
              {segment.charAt(0).toUpperCase() + segment.slice(1)} {/* Capitalize first letter */}
            </Typography>
          ) : (
            <Link key={linkPath} to={linkPath} style={{ textDecoration: 'none', color: '#1976d2' }}>
              {segment.charAt(0).toUpperCase() + segment.slice(1)}
            </Link>
          );
        })}
      </Breadcrumbs>
    );
  };
 

export default BreadCrumbs;