import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Divider,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { AdminLayout } from "../../../layouts/AdminLayout";
import BreadCrumbs from "../../../components/Breadcrumbs";
import { apiClient } from "../../../utilities/apiClient";
import { Link, useParams } from "react-router-dom";

interface Team {
  id: number;
  name: string;
  competition: {
    name: string;
  };
}

interface Club {
  id: number;
  name: string;
  shortName: string;
  alternateName?: string;
  formedYear?: string;
  league?: string;
  chairman?: string;
  website?: string;
}

const ClubView: React.FC = () => {
  const { clubId } = useParams<{ clubId: string }>();
  const [teams, setTeams] = useState<Team[]>([]);
  const [club, setClub] = useState<Club | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true)
    Promise.all([
      apiClient.get(`/clubs/${clubId}`),
      apiClient.get(`/teams/club/${clubId}`),
    ])
      .then(([clubRes, teamsRes]) => {
        setClub(clubRes.data);
        setTeams(teamsRes.data);
      })
      .catch(() => setError("Failed to load club details"))
      .finally(() => setLoading(false));

  }, [clubId]);

  if(loading){
  return (

    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
      <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="success" />
      </Backdrop>
    </Box>
  );
}
  return (
    <AdminLayout>
      <BreadCrumbs />
      <Box>
        {error && <Typography color="error">{error}</Typography>}

        {club && (
          <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="h4" gutterBottom>
                  {club.name}
                </Typography>
                <Typography variant="body1">
                  <strong>Short Name:</strong> {club.shortName}
                </Typography>
                <Typography variant="body1">
                  <strong>Alternate Name:</strong> {club.alternateName || "N/A"}
                </Typography>
                <Typography variant="body1">
                  <strong>Formed Year:</strong> {club.formedYear || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1">
                  <strong>League:</strong> {club.league || "N/A"}
                </Typography>
                <Typography variant="body1">
                  <strong>Chairman:</strong> {club.chairman || "N/A"}
                </Typography>
                <Typography variant="body1">
                  <strong>Website:</strong>{" "}
                  {club.website ? (
                    <a href={club.website} target="_blank" rel="noopener noreferrer">
                      {club.website}
                    </a>
                  ) : (
                    "N/A"
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ margin: "20px 0" }} />
            <Typography variant="body1" color="textSecondary">
              Explore the teams and manage their players.
            </Typography>
          </Paper>
        )}

        <Paper>
          <Typography variant="h6" gutterBottom style={{ padding: "20px" }}>
            Teams
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Competition</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {teams.map((team) => (
                  <TableRow key={team.id}>
                    <TableCell>{team.name}</TableCell>
                    <TableCell>{team.competition.name}</TableCell>
                    <TableCell>
                      <Button
                        component={Link}
                        to={`/admin/team/${team.id}/players`}
                        variant="contained"
                        color="success"
                        sx={{ mr: 1 }}
                      >
                        View Players
                      </Button>
                      <Button
                        component={Link}
                        to={`/admin/teams/officials/${team.id}`}
                        variant="contained"
                        color="secondary"
                      >
                        Add Officials
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
     
    </AdminLayout>
  );
};

export default ClubView;