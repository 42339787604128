import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Container,
  Snackbar,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { apiClient } from "../../../utilities/apiClient";
import { AdminLayout } from "../../../layouts/AdminLayout";
import { MaterialReactTable } from "material-react-table";
import { Multimedia } from "../../../utilities/types";

const MediaManagement = () => {
  const [media, setMedia] = useState<Multimedia[]>([]);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [newMedia, setNewMedia] = useState<Multimedia>({
    id:0,
    fileName: "",
    url: "",
    tagNames: [],
  });

  useEffect(() => {
    fetchMedia();
  }, []);

  const fetchMedia = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get("/multimedia");
      const uniqueMedia = response.data.map((item: Multimedia, index: number) => ({
        ...item,
        id: item.id ?? `media-${index}`, // Ensure unique keys
      }));
      setMedia(uniqueMedia);
    } catch (error) {
      console.error("Error fetching media:", error);
    }
    setLoading(false);
  };

  const handleAddMedia = async () => {
    setLoading(true);
    try {
      await apiClient.post("/multimedia", newMedia);
      setSnackbarMessage("Media added successfully");
      setSnackbarOpen(true);
      fetchMedia();
      setDialogOpen(false);
      setNewMedia({id:0, fileName: "", url: "", tagNames: [] });
    } catch (error) {
      setSnackbarMessage("Error adding media");
      setSnackbarOpen(true);
    }
    setLoading(false);
  };

  const columns = [
    { accessorKey: "id", header: "ID" },
    { accessorKey: "fileName", header: "File Name" },
    { accessorKey: "url", header: "URL" }, // Fixed: use `url` instead of `fileUrl`
  ];

  return (
    <AdminLayout>
      <Container>
        <Box sx={{ backgroundColor: "#e8f5e9", padding: 4, borderRadius: 2 }}>
          <Typography variant="h4" gutterBottom>
            Multimedia Management
          </Typography>
          <Button color="success" variant="contained" onClick={() => setDialogOpen(true)}>
            Add Media
          </Button>
          <MaterialReactTable columns={columns} data={media} />
        </Box>

        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle>Add Media</DialogTitle>
          <DialogContent>
            <TextField
              label="File Name"
              fullWidth
              value={newMedia.fileName}
              onChange={(e) => setNewMedia({ ...newMedia, fileName: e.target.value })}
              margin="dense"
            />
            <TextField
              label="URL"
              fullWidth
              value={newMedia.url}
              onChange={(e) => setNewMedia({ ...newMedia, url: e.target.value })}
              margin="dense"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)} disabled={loading}>
              Cancel
            </Button>
            <Button onClick={handleAddMedia} disabled={loading}>
              Add
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
        />

        <Backdrop open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </AdminLayout>
  );
};

export default MediaManagement;
