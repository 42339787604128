import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, Typography, CircularProgress, Checkbox, FormControlLabel } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { apiClient } from '../../../../../utilities/apiClient';
 

interface PositionForm {
  position: string;
  positionType: string;
  primary: boolean;
}

export function PositionsTab({ playerId }: { playerId: number }) {
  const [positions, setPositions] = useState<{ id: number; position: string; positionType: string; primary: boolean }[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [modalForm, setModalForm] = useState<PositionForm>({
    position: '',
    positionType: '',
    primary: false,
  });

  const fetchPositions = async () => {
    try {
      const response = await apiClient.get(`/player-position/player/${playerId}`);
      setPositions(response.data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching positions:', err);
      setError('Failed to fetch positions. Please try again later.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPositions();
  }, [playerId]);

  const openAddModal = () => setOpenModal(true);
  const closeAddModal = () => {
    setOpenModal(false);
    setModalForm({ position: '', positionType: '', primary: false });
  };

  const handleAddRecord = async () => {
    if (!modalForm.position || !modalForm.positionType) {
      alert('Please fill in all fields.');
      return;
    }
  
    const newRecord = {
      position: modalForm.position,
      positionType: modalForm.positionType,
      primary: modalForm.primary || false, // Default to false if not provided
      player: {
        id: playerId, // Ensure correct player ID is used
        name: '',
        middleName: '',
        lastName: '',
        bio: '',
        gender: '',
        contactNumber: '',
        dob: '',
        email: '',
        playerStatus: '',
        fileName: ''
      }
    };
  
    try {
      const response = await apiClient.post('/player-position', newRecord);
      setPositions((prev) => [...prev, response.data]); // Update positions list
      closeAddModal();
    } catch (error) {
      console.error('Error adding position record:', error);
      alert('Failed to add position record. Please try again.');
    }
  };
  

  const columns: GridColDef[] = [
    { field: 'position', headerName: 'Position', width: 150 },
    { field: 'positionType', headerName: 'Position Type', width: 150 },
    { field: 'primary', headerName: 'Primary', width: 120, type: 'boolean' },
  ];

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    );
  }

  return (
    <Box>
      <Button variant="contained" color="success" onClick={openAddModal}>
        Add New Position
      </Button>
      <Box sx={{ height: 400, width: '100%', mt: 2 }}>
        <DataGrid
          rows={positions.map((row) => ({ ...row }))}
          columns={columns}
          paginationModel={{ page: 0, pageSize: 5 }}
        />
      </Box>

      <Modal open={openModal} onClose={closeAddModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            width: 400,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" mb={2}>
            Add New Position
          </Typography>
          <TextField
            label="Position"
            fullWidth
            margin="normal"
            value={modalForm.position}
            onChange={(e) => setModalForm((prev) => ({ ...prev, position: e.target.value }))}
          />
          <TextField
            label="Position Type"
            fullWidth
            margin="normal"
            value={modalForm.positionType}
            onChange={(e) => setModalForm((prev) => ({ ...prev, positionType: e.target.value }))}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={modalForm.primary}
                onChange={(e) => setModalForm((prev) => ({ ...prev, primary: e.target.checked }))}
              />
            }
            label="Primary Position"
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="outlined" onClick={closeAddModal} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleAddRecord}>
              Add
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
