import React, { useState } from "react";
import {
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Toolbar,
  Typography,
  Button,
} from "@mui/material";
import {
  Dashboard,
  Settings,
  People,
  Event,
  Menu,
  ChevronLeft,
  Category,
  StairsTwoTone,
  Logout,
  Build,
  MediaBluetoothOff,
  Mediation,
  ArtTrack,
  CallOutlined,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { icon } from "@fortawesome/fontawesome-svg-core";
import { Home } from "../pages/content/Home";
import { HomeIcon, MagnetIcon, ScanEye, UploadCloud } from "lucide-react";

interface AdminLayoutProps {
  children: React.ReactNode;
}

const drawerWidth = 240;
const collapsedWidth = 70;

export function AdminLayout({ children }: AdminLayoutProps) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken"); 
    navigate("/login"); 
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        sx={{
          width: isSidebarOpen ? drawerWidth : collapsedWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: isSidebarOpen ? drawerWidth : collapsedWidth,
            boxSizing: "border-box",
            backgroundColor: "#2E7D32",
            color: "white",
            transition: "width 0.3s",
            overflowX: "hidden",
          },
        }}
      >
        {/* Sidebar Header */}
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {isSidebarOpen && (
              <Typography variant="h6" noWrap>
                Admin Panel
              </Typography>
            )}
            <IconButton onClick={toggleSidebar} sx={{ color: "white" }}>
              {isSidebarOpen ? <ChevronLeft /> : <Menu />}
            </IconButton>
          </Box>
        </Toolbar>

        {/* Sidebar Items */}
        <List>
          {[
            { text: "Dashboard", icon: <Dashboard />, link: "/admin", key: 1 },
            {
              text: "Federation",
              icon: <Settings />,
              link: "/admin/federation",
              key: 2,
            },
            { text: "Clubs", icon: <HomeIcon />, link: "/admin/clubs", key: 3 },
            {text:"Venue",icon:<Build/> ,link:"/admin/venue",key:8},
            {
              text: "Players",
              icon: <People />,
              link: "/admin/players/list",
              key: 4,
            },
            {
              text: "Seasons",
              icon: <Event />,
              link: "/admin/matches/seasons",
              key: 5,
            },
            {
              text: "Competition",
              icon: <Category />,
              link: "/admin/competitions",
              key: 6,
            },
            {
              text: "Fixtures",
              icon: <StairsTwoTone />,
              link: "/admin/matches/fixtures",
              key: 7,
            },
            { text: "Media", icon: <UploadCloud />, link: "/admin/media", key: 9 }, 
            { text: "Assessments", icon: <ScanEye />, link: "/admin/assessments", key: 11 }, 
            { text: "Skills", icon: <ArtTrack />, link: "/admin/skills", key: 12 }, 
            { text: "Event Register", icon: <CallOutlined />, link: "/admin/players/player-register", key: 13 }, 

            
          ].map((item) => (
            <ListItem
              component={Link}
              to={item.link}
              key={item.key}
              sx={{
                "&:hover": { backgroundColor: "#388E3C" },
                justifyContent: isSidebarOpen ? "initial" : "center",
                cursor: "pointer",
              }}
            >
              <ListItemIcon sx={{ color: "white" }}>{item.icon}</ListItemIcon>
              {isSidebarOpen && (
                <Typography variant="body1" sx={{ color: "white" }}>
                  {item.text}
                </Typography>
              )}
            </ListItem>
          ))}

          {/* Logout Button */}
          <ListItem
            onClick={handleLogout}
            sx={{
              "&:hover": { backgroundColor: "#388E3C" },
              justifyContent: isSidebarOpen ? "initial" : "center",
              cursor: "pointer",
              marginTop: "auto", // Push the logout button to the bottom
            }}
          >
            <ListItemIcon sx={{ color: "white" }}>
              <Logout />
            </ListItemIcon>
            {isSidebarOpen && (
              <Typography variant="body1" sx={{ color: "white" }}>
                Logout
              </Typography>
            )}
          </ListItem>
        </List>
      </Drawer>

      {/* Main Content */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {children}
      </Box>
    </Box>
  );
}