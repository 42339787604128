import React, { useState, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    IconButton,
    Box,
    Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { apiClient } from "../../../utilities/apiClient";
import { AdminLayout } from "../../../layouts/AdminLayout";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { Officials } from "../../../utilities/types";
import { useParams } from "react-router-dom";

const ClubOfficials: React.FC = () => {
    const { teamId } = useParams<{ teamId: string }>();
    const [officials, setOfficials] = useState<Officials[]>([]);
    const [open, setOpen] = useState(false);
    const [currentOfficial, setCurrentOfficial] = useState<Officials | null>(null);

    // Fetch officials based on teamId
    useEffect(() => {
        apiClient
            .get(`/officials/team/${teamId}`)
            .then((response) => setOfficials(response.data))
            .catch((error) => console.error("Error fetching team data:", error));
    }, [teamId]);

    const handleOpenDialog = (official: Officials | null = null) => {
        setCurrentOfficial(
            official || {
                id: undefined,
                name: "",
                middleName: "",
                lastName: "",
                email: "",
                officialRole: "",
                highestRelevantQualification: "",
                yearAcquired: "",
                dob: "",
                officialStatus: "",
                team: { // Add a default team object
                    id: Number(teamId), // Use the teamId from the URL or a default value
                    name: "",
                    clubId: 0,
                    competitionId: 0,
                },
            }
        );
        setOpen(true);
    };

    // Close dialog
    const handleCloseDialog = () => {
        setOpen(false);
        setCurrentOfficial(null);
    };

    // Save official (create or update)
    const handleSaveOfficial = async () => {
        if (!currentOfficial) return;

        try {
            if (currentOfficial.id) {
                await apiClient.put(`/officials/${currentOfficial.id}`, currentOfficial);
            } else {
                await apiClient.post("/officials", currentOfficial);
            }
            // Reload officials after save
            apiClient
                .get(`/officials/teams/${teamId}`)
                .then((response) => setOfficials(response.data))
                .catch((error) => console.error("Error fetching team data:", error));
            handleCloseDialog();
        } catch (error) {
            console.error("Error saving official:", error);
        }
    };

    // Update currentOfficial state on text field change
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setCurrentOfficial((prev) => (prev ? { ...prev, [name]: value } : null));
    };

    // Table columns definition
    const columns: GridColDef[] = [
        { field: "name", headerName: "Name", width: 150 },
        { field: "middleName", headerName: "Middle Name", width: 150 },
        { field: "lastName", headerName: "Last Name", width: 150 },
        { field: "dob", headerName: "Birth Date", width: 150 },
        { field: "email", headerName: "Email", width: 200 },
        { field: "officialRole", headerName: "Role", width: 150 },
        {
            field: "actions",
            headerName: "Actions",
            width: 150,
            renderCell: (params) => (
                <IconButton
                    color="success"
                    onClick={() => handleOpenDialog(params.row)}
                >
                    <EditIcon />
                </IconButton>
            ),
        },
    ];

    return (
        <AdminLayout>
            <Breadcrumbs />
            <Box sx={{ padding: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Club Officials Management
                </Typography>
                <Button
                    variant="contained"
                    color="success"
                    startIcon={<AddIcon />}
                    onClick={() => handleOpenDialog()}
                    sx={{ mb: 2 }}
                >
                    Add Official
                </Button>
                <Box sx={{ height: 600, width: "100%", backgroundColor: "#fafafa", borderRadius: 2 }}>
                    <DataGrid
                        rows={officials}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 5 },
                            },
                        }}
                        pageSizeOptions={[5]}
                        checkboxSelection
                    />
                </Box>

                <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
                    <DialogTitle>
                        {currentOfficial?.id ? "Edit Official" : "Add Official"}
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            name="name"
                            label="First Name"
                            type="text"
                            fullWidth
                            value={currentOfficial?.name || ""}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="dense"
                            name="middleName"
                            label="Middle Name"
                            type="text"
                            fullWidth
                            value={currentOfficial?.middleName || ""}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="dense"
                            name="lastName"
                            label="Last Name"
                            type="text"
                            fullWidth
                            value={currentOfficial?.lastName || ""}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="dense"
                            name="email"
                            label="Email"
                            type="email"
                            fullWidth
                            value={currentOfficial?.email || ""}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="dense"
                            name="highestRelevantQualification"
                            label="Highest Relevant Qualification"
                            type="text"
                            fullWidth
                            value={currentOfficial?.highestRelevantQualification || ""}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="dense"
                            name="yearAcquired"
                            label="Year Acquired"
                            type="text"
                            fullWidth
                            value={currentOfficial?.yearAcquired || ""}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="dense"
                            name="dob"
                            label="Date of Birth"
                            type="date"
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={currentOfficial?.dob || ""}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="dense"
                            name="officialRole"
                            label="Role"
                            type="text"
                            fullWidth
                            value={currentOfficial?.officialRole || ""}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="dense"
                            name="officialStatus"
                            label="Status"
                            type="text"
                            fullWidth
                            value={currentOfficial?.officialStatus || ""}
                            onChange={handleChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="error">
                            Cancel
                        </Button>
                        <Button onClick={handleSaveOfficial} color="success">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </AdminLayout>
    );
};

export default ClubOfficials;
