
export interface Federations{
    id?:number;
    name:string;
}
export interface Region{
    id: number;
    name: string;
    federation?: {
      id?: number;
      name: string;
    };
  };

  export type MediaFields = 'badge' | 'jersey' | 'logo' | 'banner' | 'fanArt';

  export interface MediaFileNames {
    badge: string;
    jersey: string;
    logo: string;
    banner: string;
    fanArt: string;
  }


  export interface Competition {
    id: number;
    name: string;
    tier: number;
    hasGroups: boolean;
    active: boolean;
    provincialTeamsOnly: boolean;
    league: boolean;
    cup: boolean;
  }
  export interface PlayerData {
    id: number;
     name: string;
     middleName: string;
     lastName: string;
     bio: string;
     gender: string;
     contactNumber: string;
     dob: string;
     email: string;
     playerStatus: string;
     province: string;
     fileName: string;
   }
   
   export interface PositionData {
     position: string;
     positionType: string;
     player: PlayerData;
     primary: boolean;
   }
   
   export interface BioDataItem {
     weight: number;
     height: number;
     period: string;
     captured: string;
     playerId:number;
   }
   
   export interface EducationData {
     institution: string;
     level: string;
     degree: string;
     fieldOfStudy: string;
     startDate: string;
     endDate: string;
   }
   export interface Competition {
    id: number;
    name: string;
    tier: number;
    hasGroups: boolean;
    active: boolean;
    provincialTeamsOnly: boolean;
    league: boolean;
    cup: boolean;
  }
  export interface Season {
    id: number;
    name: string;
    start: string;
    end: string;
    region: Region;
  }

 

  export interface Competition {
    id: number;
    name: string;
    tier: number;
    hasGroups: boolean;
    active: boolean;
    provincialTeamsOnly: boolean;
    season: {
      id: number;
      name: string;
      start: string;
      end: string;
      regionId: number;
    };
    league: boolean;
    cup: boolean;
  }
  
  export interface Fixture {
    id?: number;
    team1: Team;
    team2: Team;
    team1Score: number;
    team2Score: number;
    matchDay: number;
    competition: Competition;
    date: string;
    venue: Venue;
  }
 
  export interface Venue {
    id: number;
    name: string;
    location: string;
    capacity: number;
    description: string;
    image: string | null;
    club: Club;
  }

  export interface Club
    {
      id: number;
      name: string;
      shortName: string;
      alternateName: string;
      formedYear: number;
      league: string;
      chairman: string;
      regions:  Region;
      website: string;
      facebook: string;
      twitter: string;
      instagram: string;
      youtube: string;
      locked: boolean;
    
  }
 
  export interface Team {
    id:number;
    name: string;
    clubId: number;
    competitionId: number;
  }
  export interface Officials{
  id?: number;
  name: string;
  middleName: string;
  lastName: string;
  email: string;
  highestRelevantQualification: string;
  yearAcquired: string;
  dob: string;
  officialRole: string,
  officialStatus: string,
  team:Team
  }
  export interface TeamPlayer {
    id: number;
    team: Team;
    player: PlayerData;
    teamPlayerStatus: string;
  }

 
  export interface AssessmentType {
    id: number;
    name: string;
    description: string;
    instruction: string;
    skill: {
      id: number;
      name: string;
      description: string;
      proficiencyLevel: number;
      minScore: number;
      maxScore: number;
    };
  }
  
  export interface Statistics {
  assessmentType: string;
    assessmentTypeId?: number;
    assessmentTypeDescription?: string;
    instruction?: string;
    skillId?: number;
    skillName?: string;
    skillDescription?: string;
    proficiencyLevel?: number;
    minScore?: number;
    maxScore?: number;
    rating: number;
    assessor: string;
    assessmentDate: string;
    comments: string;
  }

  export interface Skill {
    id: number;
    name: string;
    description: string;
    proficiencyLevel: number;
    minScore: number;
    maxScore: number;
  }
  export type Multimedia = {
    id: number;
    fileName: string;
    url: string;
    tagNames: string[];
  };
  export interface PlayerRegister {
    id: number;
    title: string;
    created: string;
    player: PlayerData;
  }
 export interface Assessment {
    id: number;
    name: string;
    description: string;
    instruction: string;
    skill: Skill;
  }
  export const statusOptions = [
    "ACTIVE",
    "TRANSFERED",
    "BANNED",
    "SUSPENDED",
    "DEREGISTERED",
    "INJURED",
  ];