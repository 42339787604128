import React, { useEffect, useState } from "react";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Snackbar,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PlayerData } from "../../../utilities/types";
import { apiClient } from "../../../utilities/apiClient";
import { AdminDashboard } from "../AdminDashboard";
import { AdminLayout } from "../../../layouts/AdminLayout";
import BreadCrumbs from "../../../components/Breadcrumbs";

const PlayerList: React.FC = () => {
  const navigate = useNavigate();
  const [players, setPlayers] = useState<PlayerData[]>([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerData | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    Promise.all([
      apiClient.get("/players"),

    ]).then(([playersRes]) => {
      setPlayers(playersRes.data);
    
    }).finally(() => setLoading(false));
  }, []);

  const fetchPlayers = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get("/players"); // Replace with the correct endpoint
      setPlayers(response.data);
    } catch (error) {
      console.error("Error fetching players:", error);
    }
    setLoading(false);
  };

  const handleEditPlayer = (player: PlayerData) => {
    setSelectedPlayer(player);
    setEditDialogOpen(true);
  };

  const handleSavePlayer = async () => {
    if (selectedPlayer) {
      try {
        await apiClient.put(`/players/${selectedPlayer.id}`, selectedPlayer);
        setSnackbarMessage("Player updated successfully");
        setSnackbarOpen(true);
        setEditDialogOpen(false);
        fetchPlayers();
      } catch (error) {
        console.error("Error updating player:", error);
        setSnackbarMessage("Error updating player");
        setSnackbarOpen(true);
      }
    }
  };

  const columns: MRT_ColumnDef<PlayerData>[] = [
    { accessorKey: "id", header: "ID" },
    { accessorKey: "name", header: "Name" },
    { accessorKey: "middleName", header: "Middle Name" },
    { accessorKey: "lastName", header: "Last Name" },
    { accessorKey: "email", header: "Email" },
    { accessorKey: "contactNumber", header: "Contact Number" },
    { accessorKey: "province", header: "Province" },
    { accessorKey: "playerStatus", header: "Status" },
  ];

  return (
 <AdminLayout >
  <BreadCrumbs />

    <Box sx={{ backgroundColor: "#f5f5f5", padding: 4, borderRadius: 2 }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: "#333", fontWeight: "bold", marginBottom: 3 }}
      >
        Player List
      </Typography>

      <Button
        variant="contained"
        sx={{
          backgroundColor: "#4caf50",
          color: "white",
          '&:hover': { backgroundColor: "#388e3c" },
          marginBottom: 3,
        }}
        onClick={() => navigate("/admin/players/wizard")}
      >
        Add Player
      </Button>

      <MaterialReactTable
        columns={columns}
        data={players}
        enableRowActions
        renderRowActions={({ row }) => (
          <Box sx={{ display: "flex", gap: 1 }}>
                  <Button
              variant="contained"
              sx={{
                backgroundColor: "#4caf50",
                color: "white",
                '&:hover': { backgroundColor: "#388e3c" },
              }}
              onClick={() => navigate(`/admin/player/${row.original.id}`)}
            >
              Details
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#4caf50",
                color: "white",
                '&:hover': { backgroundColor: "#388e3c" },
              }}
              onClick={() => handleEditPlayer(row.original)}
            >
              Edit
            </Button>
          </Box>
        )}
        muiTableProps={{
          sx: {
            border: "1px solid #e0e0e0",
            borderRadius: "8px",
            backgroundColor: "#ffffff",
          },
        }}
      />

      {/* Edit Dialog */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>Edit Player</DialogTitle>
        <DialogContent>
          {selectedPlayer && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "600px" }}>
              <TextField
                label="Name"
                value={selectedPlayer.name}
                fullWidth
                onChange={(e) =>
                  setSelectedPlayer((prev) => (prev ? { ...prev, name: e.target.value } : null))
                }
              />
              <TextField
                label="Middle Name"
                value={selectedPlayer.middleName}
                fullWidth
                onChange={(e) =>
                  setSelectedPlayer((prev) => (prev ? { ...prev, middleName: e.target.value } : null))
                }
              />
              <TextField
                label="Last Name"
                value={selectedPlayer.lastName}
                fullWidth
                onChange={(e) =>
                  setSelectedPlayer((prev) => (prev ? { ...prev, lastName: e.target.value } : null))
                }
              />
              <TextField
                label="Bio"
                value={selectedPlayer.bio}
                fullWidth
                onChange={(e) =>
                  setSelectedPlayer((prev) => (prev ? { ...prev, bio: e.target.value } : null))
                }
              />
              <TextField
                label="Gender"
                value={selectedPlayer.gender}
                fullWidth
                onChange={(e) =>
                  setSelectedPlayer((prev) => (prev ? { ...prev, gender: e.target.value } : null))
                }
              />
              <TextField
                label="Contact Number"
                value={selectedPlayer.contactNumber}
                fullWidth
                onChange={(e) =>
                  setSelectedPlayer((prev) => (prev ? { ...prev, contactNumber: e.target.value } : null))
                }
              />
              <TextField
                label="Date of Birth"
                type="date"
                value={selectedPlayer.dob}
                fullWidth
                onChange={(e) =>
                  setSelectedPlayer((prev) => (prev ? { ...prev, dob: e.target.value } : null))
                }
              />
              <TextField
                label="Email"
                value={selectedPlayer.email}
                fullWidth
                onChange={(e) =>
                  setSelectedPlayer((prev) => (prev ? { ...prev, email: e.target.value } : null))
                }
              />
              <TextField
                label="Player Status"
                value={selectedPlayer.playerStatus}
                fullWidth
                onChange={(e) =>
                  setSelectedPlayer((prev) => (prev ? { ...prev, playerStatus: e.target.value } : null))
                }
              />
              <TextField
                label="Province"
                value={selectedPlayer.province}
                fullWidth
                onChange={(e) =>
                  setSelectedPlayer((prev) => (prev ? { ...prev, province: e.target.value } : null))
                }
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
          <Button
            sx={{ backgroundColor: "#4caf50", color: "white", '&:hover': { backgroundColor: "#388e3c" } }}
            onClick={handleSavePlayer}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>

    <Backdrop open={loading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <CircularProgress color="success" />
    </Backdrop>
 </AdminLayout>
  );
};

export default PlayerList;
