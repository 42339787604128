import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, Typography, CircularProgress } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { apiClient } from '../../../../../utilities/apiClient';
 

interface EducationForm {
  degree: string;
  institution: string;
  fieldOfStudy: string;
  level?: string;
  startDate: string;
  endDate: string;
}

export function EducationTab({ playerId }: { playerId: number }) {
  const [educationData, setEducationData] = useState<{ [key: string]: any }[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [modalForm, setModalForm] = useState<EducationForm>({
    degree: '',
    institution: '',
    fieldOfStudy: '',
    startDate: '',
    endDate: '',
  });

  const fetchEducationData = async () => {
    try {
      const response = await apiClient.get(`/player-education/player/${playerId}`);
      setEducationData(response.data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching education data:', err);
      setError('Failed to fetch education data. Please try again later.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEducationData();
  }, [playerId]);

  const openAddModal = () => setOpenModal(true);
  const closeAddModal = () => {
    setOpenModal(false);
    setModalForm({
      degree: '',
      institution: '',
      fieldOfStudy: '',
      startDate: '',
      endDate: '',
    });
  };

  const handleAddRecord = async () => {
    if (!modalForm.degree || !modalForm.institution || !modalForm.fieldOfStudy) {
      alert('Please fill in all fields.');
      return;
    }
  
    const newRecord = {
      institution: modalForm.institution,
      level: modalForm.level || '', // Ensure level exists (optional)
      degree: modalForm.degree,
      fieldOfStudy: modalForm.fieldOfStudy,
      startDate: modalForm.startDate || new Date().toISOString().split('T')[0], // Default to today if not provided
      endDate: modalForm.endDate || new Date().toISOString().split('T')[0], // Default to today if not provided
      player: {
        id: playerId, // Ensure correct player ID is used
        name: '',
        middleName: '',
        lastName: '',
        bio: '',
        gender: '',
        contactNumber: '',
        dob: '',
        email: '',
        playerStatus: '',
        fileName: ''
      }
    };
  
    try {
      const response = await apiClient.post('/player-education/', newRecord);
      setEducationData((prev) => [...prev, response.data]); // Add new record from response
      closeAddModal();
    } catch (error) {
      console.error('Error adding education record:', error);
      alert('Failed to add education record. Please try again.');
    }
  };
  

  const columns: GridColDef[] = [
    { field: 'institution', headerName: 'Institution', width: 200 },
    { field: 'degree', headerName: 'Degree', width: 150 },
    { field: 'fieldOfStudy', headerName: 'Field of Study', width: 200 },
    { field: 'startDate', headerName: 'Start Date', width: 150 },
    { field: 'endDate', headerName: 'End Date', width: 150 },
  ];

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    );
  }

  return (
    <Box>
      <Button variant="contained" color="success" onClick={openAddModal}>
        Add New Education Record
      </Button>
      <Box sx={{ height: 400, width: '100%', mt: 2 }}>
        <DataGrid
          rows={educationData.map((row, index) => ({ ...row, id: index }))}
          columns={columns}
          paginationModel={{ page: 0, pageSize: 5 }}
        />
      </Box>

      <Modal open={openModal} onClose={closeAddModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            width: 400,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" mb={2}>
            Add New Education Record
          </Typography>
          <TextField
            label="Institution"
            fullWidth
            margin="normal"
            onChange={(e) => setModalForm((prev) => ({ ...prev, institution: e.target.value }))}
          />
          <TextField
            label="Degree"
            fullWidth
            margin="normal"
            onChange={(e) => setModalForm((prev) => ({ ...prev, degree: e.target.value }))}
          />
          <TextField
            label="Field of Study"
            fullWidth
            margin="normal"
            onChange={(e) => setModalForm((prev) => ({ ...prev, fieldOfStudy: e.target.value }))}
          />
          <TextField
            label="Start Date"
            type="date"
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setModalForm((prev) => ({ ...prev, startDate: e.target.value }))}
          />
          <TextField
            label="End Date"
            type="date"
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => setModalForm((prev) => ({ ...prev, endDate: e.target.value }))}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="outlined" onClick={closeAddModal} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button variant="contained" color="success" onClick={handleAddRecord}>
              Add
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
