/* eslint-disable react/jsx-pascal-case */
import React, { useMemo, useState, useEffect, ReactNode } from 'react';
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_Row,
  MRT_TableInstance,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  ListItemIcon,
  MenuItem,
  Typography,
} from '@mui/material';
import { AccountCircle, GroupAdd } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { AdminLayout } from '../../../layouts/AdminLayout';
import BreadCrumbs from '../../../components/Breadcrumbs';
import { apiClient } from '../../../utilities/apiClient';

interface Federation {
  id: number;
  name: string;
}

interface Region {
  id: number;
  name: string;
  federation: Federation;
}

interface Club {
  id: number;
  name: string;
  shortName: string;
  chairman: string;
  regions: Region; // Change this to Region type
  locked: boolean;
}

 const ClubsList = () => {
  const [clubs, setClubs] = useState<Club[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    Promise.all([
      apiClient.get("/clubs")
    ]).then(([clubsRes]) => {
      setClubs(clubsRes.data);
    }).finally(() => setLoading(false));
  }, []);
  const fetchClubs = async () => {
    try {
      const response = await apiClient.get('/clubs');
      if (!response) {
        throw new Error('Failed to fetch clubs data');
      }
      const data: Club[] = await response.data;
      setClubs(data);
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo<MRT_ColumnDef<Club>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 200,
      },
      {
        accessorKey: 'shortName',
        header: 'Short Name',
        size: 150,
      },
      {
        accessorKey: 'chairman',
        header: 'Chairman',
        size: 200,
      },
      {
        accessorKey: 'regions.name', // Access the name of the region
        header: 'Region',
        size: 150,
      },
      {
        accessorKey: 'locked',
        header: 'Locked',
        size: 100,
        Cell: ({ cell }) => (
          <Typography color={cell.getValue<boolean>() ? 'error' : 'success'}>
            {cell.getValue<boolean>() ? 'Yes' : 'No'}
          </Typography>
        ),
      },
    ],
    []
  );

  const renderRowActionMenuItems = ({
    closeMenu,
    row,
  }: {
    closeMenu: () => void;
    row: MRT_Row<Club>;
  }) => [
    <MenuItem
      key="view-club"
      onClick={() => {
        navigate(`/admin/clubs/view/${row.original.id}`);
        closeMenu();
      }}
    >
      <ListItemIcon>
        <AccountCircle />
      </ListItemIcon>
      View Club
    </MenuItem>,
    <MenuItem
      key="create-team"
      onClick={() => {
        navigate(`/admin/team/club-id/${row.original.id}`);
        closeMenu();
      }}
    >
      <ListItemIcon>
        <GroupAdd />
      </ListItemIcon>
      Create Team
    </MenuItem>,
  ];

  const renderColumnActionsMenuItems = ({
    closeMenu,
    internalColumnMenuItems,
  }: {
    closeMenu: () => void;
    internalColumnMenuItems: ReactNode[];
  }) => [
    ...internalColumnMenuItems,
    <MenuItem key="action-custom" onClick={() => console.log("Custom action!")}>
      Custom Action
    </MenuItem>,
    <MenuItem key="close" onClick={closeMenu}>
      Close
    </MenuItem>,
  ];

  const renderTopToolbar = ({ table }: { table: MRT_TableInstance<Club> }) => (
    <Box
      sx={{
        backgroundColor: '#2F7C31', // Green header
        display: 'flex',
        gap: '0.5rem',
        p: '8px',
        justifyContent: 'space-between',
        color: 'white', // White text for contrast
      }}
    >
      <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
        <MRT_GlobalFilterTextField table={table} />
        <MRT_ToggleFiltersButton table={table} />
      </Box>
      <Button
        color='success'
        variant="contained"
        onClick={() => navigate('/admin/clubs/add')}
      >
        Add New Club
      </Button>
    </Box>
  );

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
  
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" variant="h6" align="center" sx={{ mt: 4 }}>
        {error}
      </Typography>
    );
  }

  return (
    <AdminLayout>

      <BreadCrumbs />

<Container>
      <MaterialReactTable
        columns={columns}
        data={clubs}
        enableColumnOrdering
        enableGlobalFilter
        enableRowActions
        enableRowSelection
        initialState={{
          showGlobalFilter: false,
          showColumnFilters: true,
        }}
        renderColumnActionsMenuItems={renderColumnActionsMenuItems}
        renderRowActionMenuItems={renderRowActionMenuItems}
        renderTopToolbar={renderTopToolbar}
        muiPaginationProps={{
          rowsPerPageOptions: [10, 20, 50],
        }}
        muiTableProps={{
          sx: {
            '& .MuiTableRow-root:nth-of-type(odd)': {
              backgroundColor: '#f5f5f5', // Light gray for odd rows
            },
            '& .MuiTableRow-root:nth-of-type(even)': {
              backgroundColor: '#ffffff', // White for even rows
            },
          },
        }}
      />
    </Container>
    
    </AdminLayout>
  );
};
export default ClubsList;