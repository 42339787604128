import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  useReactTable,
  ColumnDef,
  getCoreRowModel,
} from "@tanstack/react-table";
import { format, parse, isValid, set } from "date-fns";
import { Season, Region } from "../../../utilities/types";
import { apiClient } from "../../../utilities/apiClient";
import { AdminLayout } from "../../../layouts/AdminLayout";
import BreadCrumbs from "../../../components/Breadcrumbs";

const initialNewSeason = {
  name: "",
  start: "",
  end: "",
  regionId: "",
};

const SeasonList: React.FC = () => {
  const [seasons, setSeasons] = useState<Season[]>([]);
  const [regions, setRegions] = useState<Region[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentSeason, setCurrentSeason] = useState<Season | null>(null);
  const [formState, setFormState] = useState(initialNewSeason);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      apiClient.get("/season"),
      apiClient.get("/regions"),
    ]).then(([seasonsRes, regionsRes]) => {
      setSeasons(seasonsRes.data);
      setRegions(regionsRes.data);
    }).finally(() => setLoading(false));
  }, []);

  const openDialog = (season: Season | null = null) => {
    if (season) {
      setFormState({
        name: season.name,
        start: season.start,
        end: season.end,
        regionId: season.region?.id?.toString() || "",
      });
    } else {
      setFormState(initialNewSeason);
    }
    setCurrentSeason(season);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setCurrentSeason(null);
  };

  const handleSave = () => {
    if (currentSeason) {
      // Edit existing season
      apiClient.put(`/season/${currentSeason.id}`, {
        ...currentSeason,
        ...formState,
        region: regions.find((r) => r.id === parseInt(formState.regionId)),
      })
        .then(() => {
          setSeasons((prev) =>
            prev.map((s) =>
              s.id === currentSeason.id ? { ...currentSeason, ...formState } : s
            )
          );
          closeDialog();
        })
        .catch((error) => console.error("Error updating season:", error));
    } else {
      // Add new season
      apiClient.post("/season", {
        ...formState,
        region: regions.find((r) => r.id === parseInt(formState.regionId)),
      })
        .then((response) => {
          setSeasons((prev) => [...prev, response.data]);
          closeDialog();
        })
        .catch((error) => console.error("Error adding season:", error));
    }
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return "N/A";
    const parsedDate = parse(dateString, "yyyy-MM-dd", new Date());
    return isValid(parsedDate) ? format(parsedDate, "yyyy-MM-dd") : "Invalid Date";
  };

  const columns: ColumnDef<Season>[] = [
    { accessorKey: "id", header: "ID" },
    { accessorKey: "name", header: "Name" },
    {
      accessorKey: "start",
      header: "Start Date",
      cell: ({ row }) => <>{formatDate(row.original.start)}</>,
    },
    {
      accessorKey: "end",
      header: "End Date",
      cell: ({ row }) => <>{formatDate(row.original.end)}</>,
    },
    { accessorKey: "region.name", header: "Region" },
    {
      id: "actions",
      header: "Actions",
      cell: ({ row }) => (
        <Button  variant="contained"
        sx={{
            color: "white",
            backgroundColor:"green",
            borderColor: "white",
            "&:hover": {
              backgroundColor: "green",
              borderColor: "darkgreen",
            },
          }}
        onClick={() => openDialog(row.original)}>
          Edit
        </Button>
      ),
    },
  ];

  const table = useReactTable({
    data: seasons,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (loading) {
    return <Box>    <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
    <CircularProgress color="inherit" />
  </Backdrop>.</Box>;
  }
  return (
    <AdminLayout>
      <BreadCrumbs />
 
      <Container maxWidth="md">
        <Box>
      <Typography variant="h4" gutterBottom>
        Season Management
      </Typography>
        <Paper sx={{ padding: 2 }}>
          <Button variant="contained" color="success" onClick={() => openDialog()}>
            Add New Season
          </Button>
          <Table>
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableCell key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : typeof header.column.columnDef.header === "function"
                        ? header.column.columnDef.header(header.getContext())
                        : header.column.columnDef.header}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {table.getRowModel().rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {typeof cell.column.columnDef.cell === "function"
                        ? cell.column.columnDef.cell(cell.getContext())
                        : cell.getValue()}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>

        <Dialog open={dialogOpen} onClose={closeDialog}>
          <DialogTitle>{currentSeason ? "Edit Season" : "Add New Season"}</DialogTitle>
          <DialogContent>
            <TextField
              label="Season Name"
              fullWidth
              margin="dense"
              value={formState.name}
              onChange={(e) => setFormState({ ...formState, name: e.target.value })}
            />
            <TextField
              label="Start Date"
              type="date"
              fullWidth
              margin="dense"
              value={formState.start}
              onChange={(e) => setFormState({ ...formState, start: e.target.value })}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="End Date"
              type="date"
              fullWidth
              margin="dense"
              value={formState.end}
              onChange={(e) => setFormState({ ...formState, end: e.target.value })}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              select
              label="Region"
              fullWidth
              margin="dense"
              value={formState.regionId}
              onChange={(e) =>
                setFormState({ ...formState, regionId: e.target.value })
              }
            >
              {regions.map((region) => (
                <MenuItem key={region.id} value={region.id.toString()}>
                  {region.name}
                </MenuItem>
              ))}
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Cancel</Button>
            <Button variant="contained" color="success" onClick={handleSave}>
              {currentSeason ? "Update" : "Add"}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
        </Container>
 
    </AdminLayout>
  );
};

export default SeasonList;
