import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  Snackbar,
  Select,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Competition, Fixture, Team, Venue } from "../../../utilities/types";
import { apiClient } from "../../../utilities/apiClient";
import { AdminLayout } from "../../../layouts/AdminLayout";
import BreadCrumbs from "../../../components/Breadcrumbs";
import axios from "axios";
import { MRT_ColumnDef, MaterialReactTable } from "material-react-table";


 
const FixturesManagement: React.FC = () => {
  const [fixtures, setFixtures] = useState<Fixture[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);
  const [competitions, setCompetitions] = useState<Competition[]>([]);
  const [venues, setVenues] = useState<Venue[]>([]);  
  const [selectedFixture, setSelectedFixture] = useState<Fixture | null>(null);
  const [editDialog, setEditDialog] = useState(false);
  const [addDialog, setAddDialog] = useState(false);
  const [newFixture, setNewFixture] = useState<Partial<Fixture>>({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
     setLoading(true);
    Promise.all([
      apiClient.get("/fixtures"),
      apiClient.get("/teams"),
      apiClient.get("/competition"),
      apiClient.get("/venues"),
    ]).then(([fixturesRes, teamsRes, competitionsRes, venuesRes]) => {
      setFixtures(fixturesRes.data);
      setTeams(teamsRes.data);
      setCompetitions(competitionsRes.data);
      setVenues(venuesRes.data);
    }).finally(() => setLoading(false));
  }, []);

  const fetchFixtures = async () => {
    try {
      const response = await apiClient.get("/fixtures");
      setFixtures(response.data);
    } catch (error) {
      console.error("Error fetching fixtures:", error);
    }
  };

  const fetchVenues = async () => {
    try {
      const response = await apiClient.get("/venues");
      setVenues(response.data);
    } catch (error) {
      console.error("Error fetching fixtures:", error);
    }
  };

  const fetchTeams = async () => {
    try {
      const response = await apiClient.get("/teams");
      setTeams(response.data);
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  };

  const fetchCompetitions = async () => {
    try {
      const response = await apiClient.get("/competition");
      setCompetitions(response.data);
    } catch (error) {
      console.error("Error fetching competitions:", error);
    }
  };

  const columns: MRT_ColumnDef<Fixture>[] = [
    {
      accessorKey: "team1.name",
      header: "Home",
    },
    {
      accessorKey: "team2.name",
      header: "Away",
    },
    {
      accessorKey: "team1Score",
      header: "Home",
    },
    {
      accessorKey: "team2Score",
      header: "Away",
    },
    {
      accessorKey: "matchDay",
      header: "Match Day",
    },
    {
      accessorKey: "competition.name",
      header: "Competition",
    },
    {
      accessorKey: "venue.name",
      header: "Venue",
    },
    {
      accessorKey: "date",
      header: "Date",
    },
  ];

  const onEditFixture = (fixture: Fixture) => {
    setSelectedFixture(fixture);
    setEditDialog(true);
  };

  const onSaveFixture = async () => {
    setLoading(true);
    if (selectedFixture) {
      try {
        const updatedData = {
          id: selectedFixture.id,
          team1: selectedFixture.team1,
          team2: selectedFixture.team2,
          team1Score: selectedFixture.team1Score,
          team2Score: selectedFixture.team2Score,
          matchDay: selectedFixture.matchDay,
          competition: selectedFixture.competition,
          date: selectedFixture.date,
          venue:newFixture.venue,
        };

        await apiClient.put(`/fixtures/${selectedFixture.id}`,
          updatedData
        );

        setSnackbarMessage("Fixture updated successfully");
   
        setSnackbarOpen(true);
        fetchFixtures();
        setEditDialog(false);
      } catch (error) {
        console.error("Error updating fixture:", error);
        setSnackbarMessage("Error updating fixture");
        setSnackbarOpen(true);
      }  
         setLoading(false);
    }
  };

  const onAddFixture = async () => {
    setLoading(true);
    if (newFixture.team1 && newFixture.team2 && newFixture.competition) {
      try {
        const newFixtureData = {
  
          team1: newFixture.team1,
          team2: newFixture.team2,
          team1Score: newFixture.team1Score || 0,
          team2Score: newFixture.team2Score || 0,
          matchDay: newFixture.matchDay || 0,
          competition: newFixture.competition,
          date: newFixture.date || "",
          venue:newFixture.venue
        };

        await apiClient.post("/fixtures", newFixtureData);

        setSnackbarMessage("Fixture added successfully");
        setSnackbarOpen(true);
        fetchFixtures();
        setAddDialog(false);
        setNewFixture({});
      } catch (error) {
        console.error("Error adding fixture:", error);
        setSnackbarMessage("Error adding fixture");
        setSnackbarOpen(true);
      }
      setLoading(false);
    }
  };

  return (
    <AdminLayout>
      <Container>
      <BreadCrumbs/>
      <Box sx={{ backgroundColor: "#e8f5e9", padding: 4, borderRadius: 2 }}>
      <Typography variant="h4" gutterBottom sx={{ color: "#2e7d32", fontWeight: "bold" }}>
        Fixtures Management
      </Typography>

      <Button
        variant="contained"
        sx={{ backgroundColor: "#4caf50", color: "white", '&:hover': { backgroundColor: "#388e3c" }, marginBottom: 2 }}
        onClick={() => setAddDialog(true)}
      >
        Add Fixture
      </Button>

      <MaterialReactTable
        columns={columns}
        data={fixtures}
        enableRowActions
        renderRowActions={({ row }) => (
          <Button
            variant="contained"
            sx={{ backgroundColor: "#4caf50", color: "white", '&:hover': { backgroundColor: "#388e3c" } }}
            onClick={() => onEditFixture(row.original)}
          >
            Edit
          </Button>
        )}
      />

      {/* Edit Dialog */}
      <Dialog open={editDialog} onClose={() => setEditDialog(false)}>
        <DialogTitle>Edit Fixture</DialogTitle>
        <DialogContent>
          {selectedFixture && (
            <Box>
              <Select
                value={selectedFixture.team1.id}
                fullWidth
                onChange={(e) =>
                  setSelectedFixture((prev) =>
                    prev
                      ? {
                          ...prev,
                          team1: teams.find(
                            (team) => team.id === parseInt(e.target.value as string, 10)
                          )!,
                        }
                      : null
                  )
                }
              >
                {teams.map((team) => (
                  <MenuItem key={team.id} value={team.id.toString()}>
                    {team.name}
                  </MenuItem>
                ))}
              </Select>
              <Select
                value={selectedFixture.team2.id}
                fullWidth
                onChange={(e) =>
                  setSelectedFixture((prev) =>
                    prev
                      ? {
                          ...prev,
                          team2: teams.find(
                            (team) => team.id === parseInt(e.target.value as string, 10)
                          )!,
                        }
                      : null
                  )
                }
              >
                {teams.map((team) => (
                  <MenuItem key={team.id} value={team.id.toString()}>
                    {team.name}
                  </MenuItem>
                ))}
              </Select>

              <Select
                value={selectedFixture.competition.id}
                fullWidth
                onChange={(e) =>
                  setSelectedFixture((prev) =>
                    prev
                      ? {
                          ...prev,
                          competition: competitions.find(
                            (competition) => competition.id === parseInt(e.target.value as string, 10)
                          )!,
                        }
                      : null
                  )
                }
              >
                {competitions.map((competition) => (
                  <MenuItem key={competition.id} value={competition.id.toString()}>
                    {competition.name}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                label="Team A Score"
                value={selectedFixture.team1Score}
                fullWidth
                onChange={(e) =>
                  setSelectedFixture((prev) =>
                    prev ? { ...prev, teamAScore: parseInt(e.target.value) || 0 } : null
                  )
                }
              />
              <TextField
                label="Team B Score"
                value={selectedFixture.team2Score}
                fullWidth
                onChange={(e) =>
                  setSelectedFixture((prev) =>
                    prev ? { ...prev, teamBScore: parseInt(e.target.value) || 0 } : null
                  )
                }
              />
              <TextField
                label="Match Day"
                value={selectedFixture.matchDay}
                fullWidth
                onChange={(e) =>
                  setSelectedFixture((prev) =>
                    prev ? { ...prev, matchDay: parseInt(e.target.value) || 0 } : null
                  )
                }
              />
              <Select
              value={newFixture.venue?.id || ""}
              fullWidth
              onChange={(e) =>
                setNewFixture((prev) => ({
                  ...prev,
                  venue: venues.find(
                    (venue) => venue.id === parseInt(e.target.value as string, 10)
                  ),
                }))
              }
            >
              {venues.map((venue) => (
                <MenuItem key={venue.id} value={venue.id.toString()}>
                  {venue.name}
                </MenuItem>
              ))}
            </Select>
              <TextField
                label="Date"
                type="date"
                value={selectedFixture.date}
                fullWidth
                onChange={(e) =>
                  setSelectedFixture((prev) =>
                    prev ? { ...prev, date: e.target.value } : null
                  )
                }
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialog(false)}>Cancel</Button>
          <Button onClick={onSaveFixture}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Add Dialog */}
      <Dialog open={addDialog} onClose={() => setAddDialog(false)}>
        <DialogTitle>Add Fixture</DialogTitle>
        <DialogContent>
          <Box>
            <Select
              value={newFixture.team1?.id || ""}
              fullWidth
              onChange={(e) =>
                setNewFixture((prev) => ({
                  ...prev,
                  team1: teams.find((team) => team.id === parseInt(e.target.value as string, 10)),
                }))
              }
            >
              {teams.map((team) => (
                <MenuItem key={team.id} value={team.id.toString()}>
                  {team.name}
                </MenuItem>
              ))}
            </Select>
            <Select
              value={newFixture.team2?.id || ""}
              fullWidth
              onChange={(e) =>
                setNewFixture((prev) => ({
                  ...prev,
                  team2: teams.find((team) => team.id === parseInt(e.target.value as string, 10)),
                }))
              }
            >
        
              {teams.map((team) => (
                <MenuItem key={team.id} value={team.id.toString()}>
                  {team.name}
                </MenuItem>
              ))}
            </Select>

            <Select
              value={newFixture.competition?.id || ""}
              fullWidth
              onChange={(e) =>
                setNewFixture((prev) => ({
                  ...prev,
                  competition: competitions.find(
                    (competition) => competition.id === parseInt(e.target.value as string, 10)
                  ),
                }))
              }
            >
              {competitions.map((competition) => (
                <MenuItem key={competition.id} value={competition.id.toString()}>
                  {competition.name}
                </MenuItem>
              ))}
            </Select>
            <TextField
              label="Team A Score"
              type="number"
              value={newFixture.team1Score || ""}
              fullWidth
              onChange={(e) =>
                setNewFixture((prev) => ({
                  ...prev,
                  teamAScore: parseInt(e.target.value) || 0,
                }))
              }
            />
            <TextField
              label="Team B Score"
              type="number"
              value={newFixture.team2Score || ""}
              fullWidth
              onChange={(e) =>
                setNewFixture((prev) => ({
                  ...prev,
                  teamBScore: parseInt(e.target.value) || 0,
                }))
              }
            />
            <TextField
              label="Match Day"
              type="number"
              value={newFixture.matchDay || ""}
              fullWidth
              onChange={(e) =>
                setNewFixture((prev) => ({
                  ...prev,
                  matchDay: parseInt(e.target.value) || 0,
                }))
              }
            />
            <TextField
              label="Date"
              type="date"
              value={newFixture.date || ""}
              fullWidth
              onChange={(e) =>
                setNewFixture((prev) => ({
                  ...prev,
                  date: e.target.value,
                }))
              }
            />

<Select
              value={newFixture.venue?.id || ""}
              fullWidth
              onChange={(e) =>
                setNewFixture((prev) => ({
                  ...prev,
                  venue: venues.find(
                    (venue) => venue.id === parseInt(e.target.value as string, 10)
                  ),
                }))
              }
            >
              {venues.map((venue) => (
                <MenuItem key={venue.id} value={venue.id.toString()}>
                  {venue.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddDialog(false)}>Cancel</Button>
          <Button onClick={onAddFixture}>Add</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
    <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
      </AdminLayout>  );
};

export default FixturesManagement;
