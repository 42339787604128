/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useState } from "react";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const token = localStorage.getItem('authToken');

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      {/* Top Toolbar */}
      <div className="toolbar-wrap" style={{ padding: "0px 0px" }}>
        <div className="uk-container uk-container-center">
          <div className="tm-toolbar uk-clearfix uk-hidden-small">
            <div className="uk-float-right">
              <div className="uk-panel">
                <div className="social-top">
                  <a href="https://www.facebook.com/JuniorSablesRugby/">
                    <span className="uk-icon-small uk-icon-hover">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </span>
                  </a>
                  <a href="http://www.x.com">
                    <span className="uk-icon-small uk-icon-hover">
                      <FontAwesomeIcon icon={faTwitter} />
                    </span>
                  </a>
                  <a href="http://www.instagram.com">
                    <span className="uk-icon-small uk-icon-hover">
                      <FontAwesomeIcon icon={faInstagram} />
                    </span>
                  </a>
                  <a href="http://www.youtube.com">
                    <span className="uk-icon-small uk-icon-hover">
                      <FontAwesomeIcon icon={faYoutube} />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Navigation */}
      <div className="tm-menu-box">
        <div style={{ height: 70 }} className="uk-sticky-placeholder">
          <nav className="tm-navbar uk-navbar" style={{ margin: 0 }}>
            <div className="uk-container uk-container-right">
              {/* Logo */}
              <a className="tm-logo uk-float-left" href="/" style={{ color: "white", textDecoration: "none" }}>
                <span style={{ fontSize: "25px" }}>
                  <em>Zimbabwe</em>
                  <img
                    src="../assets/images/logo-sables.png"
                    style={{ height: "50px", width: "30px", marginLeft: "10px" }}
                    alt="logo"
                    title="logo"
                  />
                  Junior <em>Sables</em>
                </span>
              </a>

              {/* Desktop Menu */}
              <ul className={`uk-navbar-nav ${menuOpen ? "" : "uk-hidden-small"}`}>
                <li>
                  <a href="/" style={{ color: "white", textDecoration: "none" }}>
                    Home
                  </a>
                </li>
                <li>
                  <a href="team" style={{ color: "white", textDecoration: "none" }}>
                    Team
                  </a>
                </li>
                <li>
                  <a href="matches" style={{ color: "white", textDecoration: "none" }}>
                    Fixtures
                  </a>
                </li>
                <li>
                  <a href="sponsors" style={{ color: "white", textDecoration: "none" }}>
                    Sponsors
                  </a>
                </li>
                <li>
                  <a href="/contact" style={{ color: "white", textDecoration: "none" }}>
                    Contact
                  </a>
                </li>
                {token && (<li>
                  <a href="/admin" style={{ color: "white", textDecoration: "none" }}>
                    Admin
                  </a>
                </li>  )}
                    {/* Admin Link in Mobile Menu */}
            {/* {token && (
              <li style={{ padding: "10px 0" }}>
                  <a href="/contact" style={{ color: "white", textDecoration: "none" }}>
                  Admin
                </a>
              </li>
            )} */}
              </ul>

              {/* Toggle Button for Mobile */}
              <button
                className="uk-navbar-toggle uk-visible-small"
                onClick={handleToggleMenu}
                style={{
                  background: "none",
                  border: "none",
                  color: "white",
                  fontSize: "24px",
                  cursor: "pointer",
                }}
              >
                ☰
              </button>
            </div>
          </nav>
        </div>
      </div>

      {/* Mobile Menu */}
      {menuOpen && (
        <div
          className="mobile-menu"
          style={{
            position: "absolute",
            top: "70px",
            left: "0",
            width: "100%",
            background: "#2e7d32",
            padding: "10px 0",
            zIndex: 1000,
            textAlign: "center",
          }}
        >
          <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
            <li style={{ padding: "10px 0" }}>
              <a href="/" style={{ color: "white", textDecoration: "none", fontSize: "18px" }}>
                Home
              </a>
            </li>
            <li style={{ padding: "10px 0" }}>
              <a href="team" style={{ color: "white", textDecoration: "none", fontSize: "18px" }}>
                Team
              </a>
            </li>
            <li style={{ padding: "10px 0" }}>
              <a href="matches" style={{ color: "white", textDecoration: "none", fontSize: "18px" }}>
                Fixtures
              </a>
            </li>
            <li style={{ padding: "10px 0" }}>
              <a href="sponsors" style={{ color: "white", textDecoration: "none", fontSize: "18px" }}>
                Sponsors
              </a>
            </li>
            <li style={{ padding: "10px 0" }}>
              <a href="/contact" style={{ color: "white", textDecoration: "none", fontSize: "18px" }}>
                Contact
              </a>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};
