/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

export const Footer = () => {
  return (
    <>
      <div className="bottom-wrapper">
        {/* Top Footer Section */}
        <div className="tm-bottom-f-box">
          <div className="uk-container uk-container-center">
            <section
              id="tm-bottom-f"
              className="tm-bottom-f uk-grid"
              data-uk-grid-match="{target:'> div > .uk-panel'}"
              data-uk-grid-margin=""
            >
              <div className="uk-width-1-1">
                <div className="uk-panel">
                  <div className="footer-logo">
                    <a href="/">
                      {/* <img src="/public/assets/images/footer-logo-img.png" alt="Logo" /> */}
                      Zimbabwe<span> Junior</span> Sables
                    </a>
                  </div>
                  <div className="footer-socials">
                    <div className="social-top">
                      <a href="http://www.facebook.com">
                        <span className="uk-icon-small uk-icon-hover">
                          <FontAwesomeIcon icon={faFacebookF} />
                        </span>
                      </a>
                      <a href="http://www.x.com">
                        <span className="uk-icon-small uk-icon-hover">
                          <FontAwesomeIcon icon={faTwitter} />
                        </span>
                      </a>
                      <a href="http://www.instagram.com">
                        <span className="uk-icon-small uk-icon-hover">
                          <FontAwesomeIcon icon={faInstagram} />
                        </span>
                      </a>
                      <a href="http://www.youtube.com">
                        <span className="uk-icon-small uk-icon-hover">
                          <FontAwesomeIcon icon={faYoutube} />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        {/* Newsletter and Match Section */}
        <div className="tm-bottom-g-box">
          <div className="uk-container uk-container-center">
            <section
              id="tm-bottom-g"
              className="tm-bottom-f uk-grid"
              data-uk-grid-match="{target:'> div > .uk-panel'}"
              data-uk-grid-margin=""
            >
              {/* Last Match Section */}
              <div className="uk-width-1-1 uk-width-large-1-2">
                <div className="uk-panel">
                  <div className="match-list-wrap foot">
                    <div className="last-match-top">
                      <div className="last-match-title">Last match</div>
                      <div className="footer-slider-btn">
                        <a
                          href="/"
                          className="uk-slidenav uk-slidenav-contrast uk-slidenav-previous"
                          data-uk-slideshow-item="previous"
                        />
                        <a
                          href="/"
                          className="uk-slidenav uk-slidenav-contrast uk-slidenav-next"
                          data-uk-slideshow-item="next"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Newsletter Section */}
              <div className="uk-width-1-1 uk-width-large-1-2">
                <div className="uk-panel">
                  <div className="acymailing_module">
                    <form>
                      <div className="acymailing_module_form">
                        <div className="mail-title">Newsletters</div>
                        <div className="clear" />
                        <table className="acymailing_form">
                          <tbody>
                            <tr>
                              <td className="acyfield_email">
                                <span className="mail-wrap">
                                  <input
                                    id="user_email"
                                    className="inputbox"
                                    name="user[email]"
                                    style={{ width: "80%" }}
                                    placeholder="Enter your email"
                                    title="Enter your email"
                                    type="text"
                                  />
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="acysubbuttons">
                                <input
                                  className="button btn btn-primary"
                                  value="Subscribe"
                                  name="Submit"
                                  type="submit"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        {/* Footer Bottom Section */}
        <footer id="tm-footer" className="tm-footer">
          <div className="uk-container uk-container-center">
            <div className="uk-grid">
              <div className="uk-width-1-1">
                <div className="footer-wrap">
                  <div className="foot-menu-wrap">
                    <ul className="nav menu">
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="team">Team</a>
                      </li>
                      <li>
                        <a href="matches">Fixtures</a>
                      </li>
                      <li>
                        <a href="contact">Contact</a>
                      </li>
                      <li>
                        <a href="sponsors">Sponsors</a>
                      </li>
                    </ul>
                  </div>
                  <div className="copyrights">
                    Copyright © 2025 <a href="/">Thundrcraft.co.zw</a>. All
                    Rights Reserved.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};
