import React from 'react';
import { FrontendLayout } from '../../layouts/FrontendLayout';


export const Contact = () => {
    return (
        <>
          <FrontendLayout>
          <div className="bottom-wrapper"> Contact Page</div>
          </FrontendLayout>
    
      </>
      
    );
};

 