
import React from 'react';
import { FrontendLayout } from '../../layouts/FrontendLayout';
 

 
const PlayerProfile = () => {


 
    return (
        <>
<FrontendLayout>
<div className="bottom-wrapper">
<span>Profile Picture Page</span>
</div>
</FrontendLayout>
        </>
    );
};

export default PlayerProfile;