import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { AdminLayout } from "../../../layouts/AdminLayout";
import BreadCrumbs from "../../../components/Breadcrumbs";
import { Region } from "../../../utilities/types";
import { apiClient } from "../../../utilities/apiClient";

const FederationDetails: React.FC = () => {
  const { federationId } = useParams<{ federationId: string }>();
  const [regions, setRegions] = useState<Region[]>([]);
  const [federationName, setFederationName] = useState<string>("");
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [currentRegion, setCurrentRegion] = useState<Region | null>(null);
  const [editRegionName, setEditRegionName] = useState<string>("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    if (!federationId) return;
    apiClient.get(`/federation/${federationId}`)
      .then((response) => setFederationName(response.data.name))
      .catch((error) => console.error("Error fetching federation:", error));

    apiClient.get<Region[]>(`/regions/federation/${federationId}`)
      .then((response) => setRegions(response.data))
      .catch((error) => console.error("Error fetching regions:", error));
  }, [federationId]);

  const handleEditRegion = (region: Region) => {
    setCurrentRegion(region);
    setEditRegionName(region.name);
    setEditDialogOpen(true);
  };

  const handleUpdateRegion = () => {
    console.log(`====> ${JSON.stringify(currentRegion, null, 2)}`);

    if (currentRegion) {
      apiClient.put(`regions/${currentRegion.id}`, {
        ...currentRegion,
        name: editRegionName,
      })
        .then((response) => {
          setRegions(prev => prev.map(region => (region.id === currentRegion.id ? response.data : region)));
          closeEditDialog();
        })
        .catch((error) => console.error("Error updating region:", error));
    }
  };

  const closeEditDialog = () => {
    setEditDialogOpen(false);
    setCurrentRegion(null);
    setEditRegionName("");
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <AdminLayout>
      <BreadCrumbs />
      <Box sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom fontWeight="bold">
          {federationName}
        </Typography>

        {/* Data Table Section */}
        <Paper sx={{ padding: 3, borderRadius: 3 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {regions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((region) => (
                  <TableRow key={region.id} hover>
                    <TableCell>{region.id}</TableCell>
                    <TableCell>{region.name}</TableCell>
                    <TableCell align="right">
                      <Button
                        variant="outlined"
                        onClick={() => handleEditRegion(region)}
                        sx={{ marginRight: 1 }}
                      >
                        Edit
                      </Button>
                      <Button
                        color="success"
                        variant="contained"
                        onClick={() => navigate('/admin/clubs/wizard')}
                      >
                        Add Club
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination */}
          <TablePagination
            component="div"
            count={regions.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        {/* Edit Region Dialog */}
        <Dialog open={editDialogOpen} onClose={closeEditDialog}>
          <DialogTitle>Edit Region</DialogTitle>
          <DialogContent>
            <TextField
              label="Region Name"
              value={editRegionName}
              onChange={(e) => setEditRegionName(e.target.value)}
              fullWidth
              margin="dense"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeEditDialog}>Cancel</Button>
            <Button onClick={handleUpdateRegion} variant="contained" color="success">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </AdminLayout>
  );
};

export default FederationDetails;
