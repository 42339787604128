import React from 'react';
import { FrontendLayout } from '../../layouts/FrontendLayout';

 

 



export const MatchPage = () => {

    return (
        <>
        <FrontendLayout>
          <div className="bottom-wrapper">MatchPage Here</div>
        </FrontendLayout>
   
      </>
      

 
    );
};

 