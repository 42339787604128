import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, CircularProgress, Alert } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

const fetchPlayerStatistics = async (id: string) => {
  const response = await fetch(`https://api.example.com/players/${id}/statistics`); // Replace with your actual API
  if (!response.ok) {
    throw new Error('Failed to fetch player statistics');
  }
  return response.json();
};

export function PlayerStatistics() {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading, error } = useQuery({queryKey:['playerStats', id], queryFn:() =>
    fetchPlayerStatistics(id!)
});

  const columns: GridColDef[] = [
    { field: 'match', headerName: 'Match', width: 150 },
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'score', headerName: 'Score', width: 100 },
    { field: 'assists', headerName: 'Assists', width: 100 },
    { field: 'minutesPlayed', headerName: 'Minutes Played', width: 150 },
  ];

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error instanceof Error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <Box sx={{ height: 600, width: '100%', mt: 2 }}>
      <Typography variant="h4" gutterBottom>
        Player Statistics
      </Typography>
      <DataGrid rows={data || []} columns={columns}  
         paginationModel={{ page: 0, pageSize: 5 }} />
    </Box>
  );
}
