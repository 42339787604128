import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, TextField, Typography, CircularProgress, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { apiClient } from '../../../../../utilities/apiClient';

interface Team {
  id: number;
  name: string;
}

interface TeamPlayerForm {
  teamId: number;
  teamName: string;
  teamPlayerStatus: string;
}

export function TeamPlayersTab({ playerId }: { playerId: number }) {
  const [teamPlayers, setTeamPlayers] = useState<TeamPlayerForm[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [modalForm, setModalForm] = useState<TeamPlayerForm>({
    teamId: 0,
    teamName: '',
    teamPlayerStatus: 'ACTIVE',
  });

  // Fetch Team Players
  const fetchTeamPlayers = async () => {
    try {
      const response = await apiClient.get(`/team-players/player/${playerId}`);
   console.log(JSON.stringify(response.data));  
      setTeamPlayers(response.data);
      const formattedData = response.data.map((item: any) => ({
        id: item.id,
        teamId: item.team.id,
        teamName: item.team.name,
        teamPlayerStatus: item.teamPlayerStatus,
      }));
      setTeamPlayers(formattedData);
    } catch (err) {
      console.error('Error fetching team players:', err);
      setError('Failed to fetch team players. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch Teams for Dropdown
  const fetchTeams = async () => {
    try {
      const response = await apiClient.get('/teams');
      setTeams(response.data);
    } catch (err) {
      console.error('Error fetching teams:', err);
      setError('Failed to fetch teams.');
    }
  };

  useEffect(() => {
    fetchTeamPlayers();
    fetchTeams();
  }, [playerId]);

  // Open & Close Modal
  const openAddModal = () => setOpenModal(true);
  const closeAddModal = () => {
    setOpenModal(false);
    setModalForm({ teamId: 0, teamName: '', teamPlayerStatus: 'ACTIVE' });
  };

  // Add New Team Player
  const handleAddRecord = async () => {
    if (!modalForm.teamId) {
      alert('Please select a team.');
      return;
    }

    const newRecord = {
      team: {
        id: modalForm.teamId,
        name: modalForm.teamName,
      },
      player: {
        id: playerId, // Associate with correct player
      },
      teamPlayerStatus: modalForm.teamPlayerStatus,
    };

    try {
      const response = await apiClient.post('/team-players', newRecord);
      setTeamPlayers((prev) => [...prev, response.data]); // Update table
      closeAddModal();
    } catch (error) {
      console.error('Error adding team player:', error);
      alert('Failed to add team player. Please try again.');
    }
  };

  // Define Table Columns
  const columns: GridColDef[] = [
    { field: 'teamId', headerName: 'Team ID', width: 100 },
    { field: 'teamName', headerName: 'Team Name', width: 200 },
    { field: 'teamPlayerStatus', headerName: 'Status', width: 150 },
  ];

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    );
  }

  return (
    <Box>
      <Button variant="contained" color="success" onClick={openAddModal}>
        Add New Team Player
      </Button>
      <Box sx={{ height: 400, width: '100%', mt: 2 }}>
        <DataGrid
          rows={teamPlayers.map((row, index) => ({ ...row, id: index }))}
          columns={columns}
          paginationModel={{ page: 0, pageSize: 5 }}
        />
      </Box>

      {/* Add Team Player Modal */}
      <Modal open={openModal} onClose={closeAddModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            width: 400,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" mb={2}>
            Add New Team Player
          </Typography>

          {/* Dropdown for Team Selection */}
          <FormControl fullWidth margin="normal">
            <InputLabel>Team</InputLabel>
            <Select
              value={modalForm.teamId}
              onChange={(e) => {
                const selectedTeam = teams.find((team) => team.id === Number(e.target.value));
                setModalForm((prev) => ({
                  ...prev,
                  teamId: selectedTeam?.id || 0,
                  teamName: selectedTeam?.name || '',
                }));
              }}
            >
              {teams.map((team) => (
                <MenuItem key={team.id} value={team.id}>
                  {team.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Team Player Status */}
          <TextField
            label="Status"
            fullWidth
            margin="normal"
            value={modalForm.teamPlayerStatus}
            onChange={(e) => setModalForm((prev) => ({ ...prev, teamPlayerStatus: e.target.value }))}
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="outlined" onClick={closeAddModal} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button variant="contained" color="success" onClick={handleAddRecord}>
              Add
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
