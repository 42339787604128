import React from 'react';
 import { useNavigate } from 'react-router-dom';
import { isTokenExpired } from '../../utilities/TokenExpired';
import { AdminLayout } from '../../layouts/AdminLayout';
export function AdminDashboard() {
  const navigate = useNavigate();
  const token = localStorage.getItem('authToken');

  if (!token) {
    navigate('/login'); 
  }
  if (token && isTokenExpired(token)) { 
    localStorage.removeItem('authToken');
    navigate('/login');  
  }
  return (
    <AdminLayout>
      <h1>Admin Dashboard</h1>
      <p>Manage teams, players, and matches here.</p>
    </AdminLayout>
  )
}
