import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Grid,
  Snackbar,
  Stepper,
  Step,
  StepLabel,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../utilities/apiClient";
import { AdminLayout } from "../../../layouts/AdminLayout";
import BreadCrumbs from "../../../components/Breadcrumbs";
import { MediaFields, MediaFileNames } from "../../../utilities/types";

const steps = ["Add Club", "Add Media Links", "Add Venues", "Add Affiliations"];



const ClubAddingWizard: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [clubDetails, setClubDetails] = useState({
    name: "",
    shortName: "",
    alternateName: "",
    formedYear: 2024,
    league: "",
    chairman: "",
    regions: {
      id: 1, // Replace with actual region ID
      name: "Region Name", // Replace with actual region name
      federation: {
        id: 1, // Replace with actual federation ID
        name: "Federation Name", // Replace with actual federation name
      },
    },
    website: "https://clubwebsite.com", // Replace with actual website
    facebook: "https://facebook.com/club", // Replace with actual link
    twitter: "https://twitter.com/club", // Replace with actual link
    instagram: "https://instagram.com/club", // Replace with actual link
    youtube: "https://youtube.com/club", // Replace with actual link
    locked: false,
  });
  

  const [mediaLinks, setMediaLinks] = useState({
    badge: null,
    jersey: null,
    logo: null,
    banner: null,
    fanArt: null,
  });
  const [mediaFileNames, setMediaFileNames] = useState<MediaFileNames>({
    badge: '',
    jersey: '',
    logo: '',
    banner: '',
    fanArt: '',
  });
  
  const [venue, setVenue] = useState({
    name: "",
    location: "",
    capacity: 0,
    description: "",
    image: "",
  });
  
  const [affiliation, setAffiliation] = useState({
    id: 0,
    description: "",
    seasonId: 0,
    isVerified: true,
    fileName: "",
  });

  const [seasonData, setSeasonData] = useState<any[]>([]);
  const [selectedSeason, setSelectedSeason] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");


  const fetchSeasons = async () => {
    const response = await apiClient.get("/season");
    return response.data;
  };
  
  const { data: seasons, isSuccess } = useQuery({ queryKey: ["seasons"], queryFn: fetchSeasons });

  useEffect(() => {
    if (isSuccess) {
      setSeasonData(seasons);
    }
  }, [isSuccess, seasons]);

  const handleNext = () => {
    if (activeStep === 0) {
      // Validate club details (optional)
      setActiveStep((prev) => prev + 1);
    } else if (activeStep === 1) {
      // Validate media links (optional)
      setActiveStep((prev) => prev + 1);
    } else if (activeStep === 2) {
      // Validate venue (optional)
      setActiveStep((prev) => prev + 1);
    } else if (activeStep === 3) {
      // Final submission
      handleSubmit();
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleMediaLinkChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setMediaLinks((prev) => ({
        ...prev,
        [field]: file,
      }));

      // Update the file name state
      setMediaFileNames((prev) => ({
        ...prev,
        [field]: file.name,
      }));
    }
  };
 

  const handleSubmit = async () => {
    try {
      // Submit club data
      const clubResponse = await apiClient.post("/clubs", clubDetails);
  
      // Create FormData for media links
      const formData = new FormData();
      formData.append('clubId', clubResponse.data.id); // Append club ID
  
      // Append media files to the FormData
      for (const [key, value] of Object.entries(mediaLinks)) {
        if (value) {
          formData.append(key, value);
        }
      }
      await apiClient.post(`/media-links/create?clubId=${clubResponse.data.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      // Venue (optional)
      if (venue.name) {

        await apiClient.post("/venues", { ...venue, club: clubResponse.data });
      }
  
      // Affiliations
      if (affiliation.description) {
        affiliation.seasonId = seasonData[0]?.id || 0; // Use the first season ID
        await apiClient.post("/affiliations", { ...affiliation, club: clubResponse.data });
      }
  
      setSnackbarMessage("Club details added successfully!");
      setSnackbarOpen(true);
      resetForm();
    } catch (error) {
      setSnackbarMessage("Error adding club details.");
      setSnackbarOpen(true);
      console.error(error); // Log error for debugging
    }
  };
  const resetForm = () => {
    setClubDetails({
      name: "",
      shortName: "",
      alternateName: "",
      formedYear: 2024,
      league: "",
      chairman: "",
      regions: {
        id: 1,
        name: "Region Name",
        federation: {
          id: 1,
          name: "Federation Name",
        },
      },
      website: "",
      facebook: "",
      twitter: "",
      instagram: "",
      youtube: "",
      locked: false,
    });
    setMediaLinks({
      badge: null,
      jersey: null,
      logo: null,
      banner: null,
      fanArt: null,
 
    });
    setVenue({
      name: "",
      location: "",
      capacity: 0,
      description: "",
      image: "",
    });
    setAffiliation({
      id: 0,
      description: "",
      seasonId: 0,
      isVerified: true,
      fileName: "",
    });
    setActiveStep(0);
    setSelectedSeason(0)
  };


  return (
    
    <AdminLayout>
      <BreadCrumbs/>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '20vh' }}>
      <div style={{ width: '80%', maxWidth: '900px' }}>
        <Stepper activeStep={activeStep} >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <form>
          {activeStep === 0 && (
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <TextField
                  label="Club Name"
                  value={clubDetails.name}
                  onChange={(e) => setClubDetails({ ...clubDetails, name: e.target.value })}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  label="Short Name"
                  value={clubDetails.shortName}
                  onChange={(e) => setClubDetails({ ...clubDetails, shortName: e.target.value })}
                  fullWidth
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  label="Alternate Name"
                  value={clubDetails.alternateName}
                  onChange={(e) => setClubDetails({ ...clubDetails, alternateName: e.target.value })}
                  fullWidth
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  label="Formed Year"
                  type="number"
                  value={clubDetails.formedYear}
                  onChange={(e) => setClubDetails({ ...clubDetails, formedYear: Number(e.target.value) })}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  label="League"
                  value={clubDetails.league}
                  onChange={(e) => setClubDetails({ ...clubDetails, league: e.target.value })}
                  fullWidth
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  label="Chairman"
                  value={clubDetails.chairman}
                  onChange={(e) => setClubDetails({ ...clubDetails, chairman: e.target.value })}
                  fullWidth
                />
              </Grid>
              {/* Add region selection here */}
            </Grid>
          )}

          {activeStep === 1 && (
           <Grid container spacing={2}>
         {[
  { label: "Upload Badge", field: "badge" },
  { label: "Upload Jersey", field: "jersey" },
  { label: "Upload Logo", field: "logo" },
  { label: "Upload Banner", field: "banner" },
  { label: "Upload Fan Art", field: "fanArt" },
].map((item, index) => (
  <Grid item xs={6} key={index}>
    <input
      accept="image/*"
      style={{ display: 'none' }}
      id={`upload-${item.field}`}
      type="file"
      onChange={(e) => handleMediaLinkChange(e, item.field)}
    />
    <label htmlFor={`upload-${item.field}`}>
      <Button variant="contained" component="span" color="success" fullWidth>
        {item.label}
      </Button>
    </label>
    {/* Display the uploaded file name */}
    {mediaFileNames[item.field as MediaFields] && (
      <div style={{ marginTop: 8 }}>
        Uploaded: {mediaFileNames[item.field as MediaFields]}
      </div>
    )}
  </Grid>
     ))}
   </Grid>
          )}

          {activeStep === 2 && (
            
            <Grid container spacing={2} padding={10}>
              <Grid item xs={9}>
                <TextField
                  label="Venue Name"
                  value={venue.name}
                  onChange={(e) => setVenue({ ...venue, name: e.target.value })}
                  fullWidth
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  label="Location"
                  value={venue.location}
                  onChange={(e) => setVenue({ ...venue, location: e.target.value })}
                  fullWidth
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  label="Capacity"
                  type="number"
                  value={venue.capacity}
                  onChange={(e) => setVenue({ ...venue, capacity: Number(e.target.value) })}
                  fullWidth
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  label="Description"
                  value={venue.description}
                  onChange={(e) => setVenue({ ...venue, description: e.target.value })}
                  fullWidth
                />
              </Grid>
          
            </Grid>
          )}

          {activeStep === 3 && (
                <Grid container spacing={5}>
                <Grid item xs={9}>
                  <TextField
                    label="Affiliation Description"
                    value={affiliation.description}
                    onChange={(e) => setAffiliation({ ...affiliation, description: e.target.value })}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    label="Name"
                    value={affiliation.fileName}
                    onChange={(e) => setAffiliation({ ...affiliation, fileName: e.target.value })}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={9}>
                  <FormControl fullWidth>
                    <InputLabel id="season-label">Select Season</InputLabel>
                    <Select
                      labelId="season-label"
                      value={selectedSeason}
                      onChange={(e) =>  setSelectedSeason(Number(e.target.value))}  
                      required
                    >
                      <MenuItem value={0} disabled>Select a season</MenuItem>
                      {seasonData.map((season) => (
                        <MenuItem key={season.id} value={season.id}>
                          {season.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
          )}

          <Grid container spacing={2} justifyContent="flex-end" style={{ marginTop: 16 }}>
            <Button disabled={activeStep === 0} onClick={handleBack} color="success">
              Back
            </Button>
            <Button variant="contained" onClick={handleNext} color="success">
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Grid>
        </form>

        <Snackbar
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          autoHideDuration={6000}
        />
      </div>
    </div>
    </AdminLayout>
    
  );
};

export default ClubAddingWizard;