import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Container,
  Typography,
  TextField,
  Button,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Backdrop,
  CircularProgress
} from '@mui/material';
import { apiClient } from '../../../utilities/apiClient';
import { AdminLayout } from '../../../layouts/AdminLayout';
import BreadCrumbs from '../../../components/Breadcrumbs';

interface Season {
  id: number;
  name: string;
  start: string;
  end: string;
  regionId: number;
}

interface Competition {
  id: number;
  name: string;
  tier: number;
  hasGroups: boolean;
  active: boolean;
  provincialTeamsOnly: boolean;
  league: boolean;
  cup: boolean;
  season: Season;
}

const fetchCompetitions = async (): Promise<Competition[]> => {
  const response = await apiClient.get('/competition/');
  return response.data;
};

const fetchSeasons = async (): Promise<Season[]> => {
  const response = await apiClient.get('/season');
  return response.data;
};

const createCompetition = async (competition: Omit<Competition, 'id'>): Promise<Competition> => {
  const response = await apiClient.post('/competition', competition);
  return response.data;
};

const updateCompetition = async (competition: Competition): Promise<Competition> => {
  const response = await apiClient.put(`/competition/${competition.id}`, competition);
  return response.data;
};

const CompetitionManager: React.FC = () => {
  const queryClient = useQueryClient();
  const { data: competitions = [], isLoading } = useQuery({ queryKey: ['competitions'], queryFn: fetchCompetitions });
  const { data: seasons = [] } = useQuery({ queryKey: ['seasons'], queryFn: fetchSeasons });
  

  const [newCompetition, setNewCompetition] = useState<Competition>({
    id: 0, 
    name: '',
    tier: 1,
    hasGroups: false,
    active: true,
    provincialTeamsOnly: true,
    league: true,
    cup: false,
    season: { id: 0, name: '', start: '', end: '', regionId: 0 }
  });

 
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  const createMutation = useMutation({
    mutationFn: createCompetition,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['competitions'] });
      setSnackbarMessage('Competition added successfully!');
      setSnackbarOpen(true);
      setDialogOpen(false);
    },
    onError: (error: Error) => {
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    },
  });

  const updateMutation = useMutation({
    mutationFn: updateCompetition,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['competitions'] });
      setSnackbarMessage('Competition updated successfully!');
      setSnackbarOpen(true);
      setDialogOpen(false);
    },
    onError: (error: Error) => {
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    },
  });

  const handleCreate = () => {
    setNewCompetition({
        id:0,
      name: '',
      tier: 1,
      hasGroups: false,
      active: true,
      provincialTeamsOnly: true,
      league: true,
      cup: false,
      season: { id: 0, name: '', start: '', end: '', regionId: 0 }
    });
    setDialogOpen(true);
  };

  const handleDialogSubmit = () => {
    if (newCompetition.name.trim()) {
      if ('id' in newCompetition && newCompetition.id) {
        // Ensure newCompetition has the correct type for update
        const competitionToUpdate: Competition = {
          ...newCompetition,
          id: newCompetition.id // Ensure that id is included
        };
        updateMutation.mutate(competitionToUpdate); // Update existing competition
      } else {
        createMutation.mutate(newCompetition); // Create new competition
      }
    } else {
      setSnackbarMessage('Please provide a valid competition name.');
      setSnackbarOpen(true);
    }
  };

  const handleUpdate = (competition: Competition) => {
    setNewCompetition(competition);
    setDialogOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (isLoading) {
    return <Typography>Loading competitions...</Typography>;
  }

  function handleAddTeams(competition: Competition): void {
    throw new Error('Function not implemented.');
  }

  return (
    <AdminLayout>
    <BreadCrumbs/>

    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Competition Management
      </Typography>
      <Button variant="contained" color="success" onClick={handleCreate} sx={{ mt: 2 }}>
        Add Competition
      </Button>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="md" fullWidth scroll="body">
      <DialogTitle>
    {dialogOpen &&  'Add/Update Competition'}
  </DialogTitle>
  <DialogContent dividers sx={{ maxHeight: '400px', overflowY: 'auto' }}>
  <TextField
    label="Name"
    value={newCompetition.name}
    onChange={(e) => setNewCompetition({ ...newCompetition, name: e.target.value })}
    fullWidth
    margin="dense"
  />
  <TextField
    label="Tier"
    type="number"
    value={newCompetition.tier}
    onChange={(e) => setNewCompetition({ ...newCompetition, tier: Number(e.target.value) })}
    fullWidth
    margin="dense"
  />

  <FormControl fullWidth margin="dense">
    <InputLabel>Has Groups</InputLabel>
    <Select
      value={newCompetition.hasGroups ? 'Yes' : 'No'}
      onChange={(e) => setNewCompetition({ ...newCompetition, hasGroups: e.target.value === 'Yes' })}
    >
      <MenuItem value="Yes">Yes</MenuItem>
      <MenuItem value="No">No</MenuItem>
    </Select>
  </FormControl>
  
  <FormControl fullWidth margin="dense">
    <InputLabel>Active</InputLabel>
    <Select
      value={newCompetition.active ? 'Yes' : 'No'}
      onChange={(e) => setNewCompetition({ ...newCompetition, active: e.target.value === 'Yes' })}
    >
      <MenuItem value="Yes">Yes</MenuItem>
      <MenuItem value="No">No</MenuItem>
    </Select>
  </FormControl>
  
  <FormControl fullWidth margin="dense">
    <InputLabel>Provincial Teams Only</InputLabel>
    <Select
      value={newCompetition.provincialTeamsOnly ? 'Yes' : 'No'}
      onChange={(e) => setNewCompetition({ ...newCompetition, provincialTeamsOnly: e.target.value === 'Yes' })}
    >
      <MenuItem value="Yes">Yes</MenuItem>
      <MenuItem value="No">No</MenuItem>
    </Select>
  </FormControl>
  
  <FormControl fullWidth margin="dense">
    <InputLabel>League</InputLabel>
    <Select
      value={newCompetition.league ? 'Yes' : 'No'}
      onChange={(e) => setNewCompetition({ ...newCompetition, league: e.target.value === 'Yes' })}
    >
      <MenuItem value="Yes">Yes</MenuItem>
      <MenuItem value="No">No</MenuItem>
    </Select>
  </FormControl>
  
  <FormControl fullWidth margin="dense">
    <InputLabel>Cup</InputLabel>
    <Select
      value={newCompetition.cup ? 'Yes' : 'No'}
      onChange={(e) => setNewCompetition({ ...newCompetition, cup: e.target.value === 'Yes' })}
    >
      <MenuItem value="Yes">Yes</MenuItem>
      <MenuItem value="No">No</MenuItem>
    </Select>
  </FormControl>
  
  <FormControl fullWidth margin="dense">
    <InputLabel>Season</InputLabel>
    <Select
      value={newCompetition.season.id}
      onChange={(e) => {
        const selectedSeason = seasons.find(season => season.id === e.target.value);
        setNewCompetition({ ...newCompetition, season: selectedSeason || newCompetition.season });
      }}
    >
      {seasons.map(season => (
        <MenuItem key={season.id} value={season.id}>{season.name}</MenuItem>
      ))}
    </Select>
  </FormControl>
</DialogContent>
  <DialogActions>
    <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
    <Button onClick={handleDialogSubmit} variant="contained" color="primary">Submit</Button>
  </DialogActions>
</Dialog>
      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Tier</TableCell>
              <TableCell>Has Groups</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Provincial Teams Only</TableCell>
              <TableCell>League</TableCell>
              <TableCell>Cup</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {competitions.map((competition) => (
              <TableRow key={competition.id}>
                <TableCell>{competition.name}</TableCell>
                <TableCell>{competition.tier}</TableCell>
                <TableCell>{competition.hasGroups ? 'Yes' : 'No'}</TableCell>
                <TableCell>{competition.active ? 'Yes' : 'No'}</TableCell>
                <TableCell>{competition.provincialTeamsOnly ? 'Yes' : 'No'}</TableCell>
                <TableCell>{competition.league ? 'Yes' : 'No'}</TableCell>
                <TableCell>{competition.cup ? 'Yes' : 'No'}</TableCell>
                <TableCell>
                  <Stack direction="row" spacing={1}>
                    <Button  color="success" onClick={() => handleUpdate(competition)}>Edit</Button> |
                    <Button  color="success" onClick={() => handleAddTeams(competition)}>Add Team</Button>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar open={snackbarOpen} onClose={handleSnackbarClose} message={snackbarMessage} autoHideDuration={4000} />
      <Backdrop open={isLoading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
    </AdminLayout>
  );
};

export default CompetitionManager;