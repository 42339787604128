import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AdminDashboard } from './pages/dashboard/AdminDashboard';
import { PlayerDetails } from './pages/dashboard/players/details/PlayerDetails';
import { PlayerStatistics } from './pages/dashboard/players/statistics/PlayerStatistics';
import Federation from './pages/dashboard/federation/Federation';
import AddClub from './pages/dashboard/clubs/AddClub';
import ClubAddingWizard from './pages/dashboard/clubs/ClubAddingWizard';
import AddTeam from './pages/dashboard/team/AddTeam';
import CreatePlayerWizard from './pages/dashboard/players/CreatePlayerWizard';
import CompetitionManager from './pages/dashboard/competition/CompetitionManager';
import FederationDetails from './pages/dashboard/federation/FederationDetails';
import SeasonList from './pages/dashboard/season/SeasonList';
import NotFound from './pages/NotFound';
import ClubView from './pages/dashboard/clubs/ClubView';
import TeamPlayersUpload from './pages/dashboard/teamplayers/TeamPlayersUpload';
import ClubOfficials from './pages/dashboard/clubs/ClubOfficials';
import TeamView from './pages/dashboard/teamplayers/TeamView';
import ClubsList from './pages/dashboard/clubs/ClubsList';
import FixturesManagement from './pages/dashboard/fixtures/FixturesManagement';
import PlayerList from './pages/dashboard/players/PlayerList';
import LoginPage from './pages/auth/LoginPage';
import VenueList from './pages/dashboard/venue/VenueList';
import { About } from './pages/content/About';
import { Contact } from './pages/content/Contact';
import { MatchPage } from './pages/content/MatchPage';
import ProfilePage from './pages/content/ProfilePage';
import { Sponsors } from './pages/content/Sponsors';
import { Team } from './pages/content/Team';
import { Home } from './pages/content/Home';
import UnderConstruction from './pages/content/UnderConstractuction';
import { TeamPages } from './components/TeamPages';
import MediaManagement from './pages/dashboard/media/MediaManagement';
import SkillManagement from './pages/dashboard/skills/SkillsManagement';
import AssessmentManagement from './pages/dashboard/assessment/AssessmentManagement';
import PlayerRegisterManagement from './pages/dashboard/players/register/PlayerRegisterManagement';

function App() {
  return (
    <Router>
      <Routes>
          {/* Website Views  */}
          {/*  */}
          {/* <Route path="/" element={<Home />} /> */}
          {/* <Route path="/" element={<UnderConstruction />} /> */}
        <Route path="/about" element={<About />} />
        <Route path="/team" element={<TeamPages/>}/> 
       <Route path="/matches" element={<MatchPage/>}/>
      <Route path="/players" element={<Team/>}/>
      <Route path="/player-profile/:id" element={<ProfilePage/>}/>
      <Route path="/sponsors" element={<Sponsors/>}/>
      <Route path="contact" element={<Contact/>}/>

      <Route path="/" element={<AdminDashboard />} />
        <Route path="/admin" element={<AdminDashboard />} />
        <Route path="/login" element={<LoginPage />} />
       <Route path='/admin/federation' element={<Federation/>}/>
       <Route path='/admin/media' element={<MediaManagement/>}/>
       <Route path='/admin/skills' element={<SkillManagement/>}/>
       <Route path='/admin/assessments' element={<AssessmentManagement/>}/>
       <Route path='/admin/clubs' element={<ClubsList/>}/>
       <Route path='/admin/clubs/add' element={<AddClub/>}/>
       <Route path='/admin/clubs/view/:clubId' element={<ClubView/>}/>
       <Route path='/admin/clubs/wizard' element={<ClubAddingWizard/>}/>
       <Route path='/admin/venue' element={<VenueList/>}/>
        <Route path="/admin/players/list" element={<PlayerList />} />
        <Route path="/admin/players/wizard" element={<CreatePlayerWizard />} />
        <Route path="/admin/players/player-register" element={<PlayerRegisterManagement />} />
        <Route path="/admin/player/:id" element={<PlayerDetails />} />
        <Route path="/admin/player/:id/statistics" element={<PlayerStatistics />} />
        <Route path="/admin/team/club-id/:clubId" element={<AddTeam />}/>
        <Route path='/admin/competitions' element={<CompetitionManager/>}/>
        <Route path="/admin/federation/view/:federationId" element={<FederationDetails/>} />
   
        <Route path='/admin/matches/seasons' element={<SeasonList/>}/>
        <Route path="/admin/matches/fixtures" element={<FixturesManagement />} />

        <Route path="/admin/matches/team-player/:teamId" element={<FederationDetails/>} />
        <Route path="/admin/team/:teamId/players" element={<TeamView />} />
        <Route path='/admin/teams/:teamId/add-players' element={<TeamPlayersUpload/>}/>
        <Route path='/admin/teams/officials/:teamId' element={<ClubOfficials/>}/>

      

      <Route path="*" element={<NotFound />} /> 
      </Routes>
    </Router>
  );
}

export default App;
