import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  AppBar,
  Box,
  Tabs,
  Tab,
  Typography,
  CircularProgress,
  Paper,
  Avatar,
} from "@mui/material";
 
 
import { AdminLayout } from "../../../../layouts/AdminLayout";
import { apiClient } from "../../../../utilities/apiClient";
import { EducationTab } from "./tabs/EducationTab";
import { BiodataTab } from "./tabs/BiodataTab";
import { PositionsTab } from "./tabs/PositionsTab";
import { StatisticsTab } from "./tabs/StatisticsTab";
import { TeamPlayersTab } from "./tabs/TeamPlayersTab";
import { BASE_URL_LIVE } from "../../../../utilities/constants";
import { InjuryRecordsTab } from "./tabs/InjuryRecordsTab";

interface TabPanelProps {
  children?: React.ReactNode;
  value: number;
  index: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{ mt: 2 }}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

export function PlayerDetails() {
  const { id } = useParams(); 
  const [tabValue, setTabValue] = useState(0);
  interface PlayerData {
    fileName: string;
    name: string;
    middleName: string;
    lastName: string;
    playerStatus: string;
    // Add other fields as necessary
  }

  const [player, setPlayer] = useState<PlayerData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchPlayerDetails = async () => {
      try {
        const response = await apiClient.get(`/players/${id}`);
        setPlayer(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching player details:", err);
        setError("Failed to fetch player details. Please try again later.");
        setLoading(false);
      }
    };

    fetchPlayerDetails();
  }, [id]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  if (!player) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography variant="h6">Player not found.</Typography>
      </Box>
    );
  }

  return (
    <AdminLayout>
      <Box sx={{ p: 4 }}>
        {/* Player Header */}
        <Box display="flex" alignItems="center" mb={4}>
          <Avatar
            src={`${BASE_URL_LIVE}/file/${player.fileName}`}
            alt={player.name}
            sx={{ width: 100, height: 100, mr: 3 }}
          />
          <Box>
            <Typography variant="h4" gutterBottom>
              {player.name} {player.middleName} {player.lastName}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {player.playerStatus}
            </Typography>
          </Box>
        </Box>

        {/* Tabs */}
        <AppBar position="static" sx={{ backgroundColor: "#2E7D32" }}>
        <Tabs
  value={tabValue}
  onChange={handleTabChange}
  variant="scrollable"
  sx={{
    backgroundColor: 'green', // Green background
    color: 'white', // Default text color
    '& .MuiTabs-indicator': {
      backgroundColor: 'white', // Indicator color
    },
  }}
>
  <Tab
    label="Education"
    sx={{ color: 'white', '&.Mui-selected': { color: 'white' } }}
  />
  <Tab
    label="Biodata"
    sx={{ color: 'white', '&.Mui-selected': { color: 'white' } }}
  />
  <Tab
    label="Positions"
    sx={{ color: 'white', '&.Mui-selected': { color: 'white' } }}
  />
  <Tab
    label="Teams"
    sx={{ color: 'white', '&.Mui-selected': { color: 'white' } }}
  />
  <Tab
    label="Assessments"
    sx={{ color: 'white', '&.Mui-selected': { color: 'white' } }}
  />
  <Tab
    label="Injury Records"
    sx={{ color: 'white', '&.Mui-selected': { color: 'white' } }}
  />
</Tabs>

        </AppBar>

        {/* Tab Panels */}
        <TabPanel value={tabValue} index={0}>
          <EducationTab playerId={Number(id)}/>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <BiodataTab playerId={Number(id)}/>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <PositionsTab  playerId={Number(id)}/>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <TeamPlayersTab  playerId={Number(id)}/>
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <StatisticsTab playerId={Number(id)}/>
        </TabPanel>
       <TabPanel value={tabValue} index={5}>
            <InjuryRecordsTab playerId={Number(id)}/>
        </TabPanel> 
      </Box> 
    </AdminLayout>
  );
}