import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
  Cell,
} from "@tanstack/react-table";
import axios from "axios";
import { AdminLayout } from "../../../layouts/AdminLayout";
import { useNavigate } from "react-router-dom"; // Import useNavigate for routing
import { Federations } from "../../../utilities/types";
import { apiClient } from "../../../utilities/apiClient";

 

const columnHelper = createColumnHelper<Federations>();

const Federation: React.FC = () => {
  const [federations, setFederations] = useState<Federations[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentFederation, setCurrentFederation] = useState<Federations | null>(null);
  const [newFederationName, setNewFederationName] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); 

  useEffect(() => {
    setLoading(true);
    Promise.all([
      apiClient.get("/federation"),
    ]).then(([fixturesRes]) => {
      setFederations(fixturesRes.data);
    }).finally(() => setLoading(false));
  }, []);


  const fetchFederations = () => {
    apiClient
      .get<Federations[]>("/federation")
      .then((response) => setFederations(response.data))
      .catch((error) => console.error("Error fetching federations:", error));
  };

  const handleAddFederation = () => {
    apiClient
      .post("/federation", { name: newFederationName })
      .then((response) => {
        setFederations((prev) => [...prev, response.data]);
        closeDialog();
      })
      .catch((error) => console.error("Error adding federation:", error));
  };

  // Define the handleEditFederation function here
  const handleEditFederation = (federation: Federations) => {
    setCurrentFederation(federation);
    setNewFederationName(federation.name);
    setEditMode(true);
    setDialogOpen(true);
  };

  const handleUpdateFederation = () => {
    if (!currentFederation || currentFederation.id === undefined) return;
  
    apiClient
      .put(`federation/${currentFederation.id}`, { 
        id: currentFederation.id, // Include ID in the object sent to the backend
        name: newFederationName 
      })
      .then((response) => {
        setFederations((prev) =>
          prev.map((fed) => (fed.id === currentFederation.id ? response.data : fed))
        );
        closeDialog();
      })
      .catch((error) => console.error("Error updating federation:", error));
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setEditMode(false);
    setCurrentFederation(null);
    setNewFederationName("");
  };

  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("name", {
      header: "Name",
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: ({ row }) => {
        const federation = row.original;
        return (
          <Box>
            <Button
              variant="outlined"
              color="success"
              size="small"
              ///admin/federation/view/
              onClick={() => navigate(`/admin/federation/view/${federation.id}`)}
              style={{ marginRight: "8px" }}
            >
              View
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => handleEditFederation(federation)} 
            >
              Edit
            </Button>
          </Box>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: federations,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
   if(loading){
      return <AdminLayout>
        <Typography variant="h4" gutterBottom>
        <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
        </Typography>
      </AdminLayout>  
    }
  return (
 
    <AdminLayout>
      <Typography variant="h4" gutterBottom>
        Federation Management
      </Typography>
      <Button
        variant="contained"
        color="success"
        onClick={() => setDialogOpen(true)}
        style={{ marginBottom: "16px" }}
      >
        Add Federation
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableCell
                    key={header.id}
                    style={{ fontWeight: "bold", backgroundColor: "#2E7D32", color: "white" }}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell: Cell<Federations, any>) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog for Adding or Editing Federation */}
      <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogTitle>{editMode ? "Edit Federation" : "Add Federation"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Federation Name"
            value={newFederationName}
            onChange={(e) => setNewFederationName(e.target.value)}
            fullWidth
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button
            onClick={editMode ? handleUpdateFederation : handleAddFederation}
            variant="contained"
            color="success"
          >
            {editMode ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </AdminLayout>
  );
};

export default Federation;